import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
  getSync,
  startPollingSync,
  stopPollingSync,
  startSync,
} from 'actions/syncs-actions';
import { simpleSelectorFor } from 'selectors/syncs-selectors';
import IconLabel from 'components/icon-label';
import TimeCircleIcon from 'components/icons/time-circle-icon';
import SyncStatus from 'components/sync-status';
import TickCircleIcon from 'components/icons/tick-circle-icon';
import CrossCircleIcon from 'components/icons/cross-circle-icon';
import WarningCircleIcon from 'components/icons/warning-circle-icon';
import Feedback from 'components/feedback';
import SpinnerLabel from 'components/spinner-label';
import { SYNC_PROP_TYPES } from 'constants/proptypes';

const LatestSync = ({
  getSync,
  sync = null,
  startPollingSync,
  stopPollingSync,
  startSync,
}) => {
  const [retrying, setRetrying] = useState(false);

  useEffect(() => {
    getSync('latest');

    startPollingSync('latest');

    return () => {
      stopPollingSync();
    };
  }, []);

  useEffect(() => {
    setRetrying(false);
  }, [sync?.id]);

  const retrySync = () => {
    if (retrying) return;

    setRetrying(true);
    startSync(false, false);
  };

  return (
    <>
      <h2>Latest Sync</h2>
      <div className="sync-status">
        {!sync && (
          <Feedback label="Please hold..." icon={TimeCircleIcon}>
            Your sync has been enqueued and should start shortly.
          </Feedback>
        )}
        {sync?.status === 'in_progress' && (
          <IconLabel label="In Progress" icon={TimeCircleIcon} />
        )}
        {sync?.status === 'succeeded' && (
          <IconLabel
            label="The latest sync was a success"
            icon={TickCircleIcon}
            className="success"
          />
        )}
        {sync?.status === 'failed' && (
          <IconLabel
            label="The latest sync attempt failed"
            icon={CrossCircleIcon}
            className="error"
          />
        )}
        {sync?.status === 'retrying' && (
          <IconLabel
            label="There was an unexpected problem with a portion of this sync. This type problem often resolves itself and will be retried shortly."
            icon={WarningCircleIcon}
            className="warning"
          />
        )}
        {sync?.status === 'failed' && <div>Failed {sync?.updatedAgo} ago</div>}
        {sync?.status === 'succeeded' && (
          <div>Finished {sync?.updatedAgo} ago</div>
        )}
        {(sync?.status === 'in_progress' || sync?.status === 'retrying') && (
          <div>Started {sync?.createdAgo} ago</div>
        )}
      </div>
      <SyncStatus sync={sync} />
      {sync?.status === 'failed' && (
        <>
          <Feedback
            label="An error occurred while syncing with Squarespace."
            status="error"
          >
            <ol>
              <li>Use the Re-sync button to restart the sync process.</li>
              <li>
                Read our <NavLink to="/support">Support FAQ</NavLink> for common
                troubleshooting tips.
              </li>
              <li>
                If the error still occurs, please{' '}
                <a
                  href="https://mailchimp.com/contact/"
                  target="_blank"
                  rel="noreferrer"
                >
                  contact our support team
                </a>
                .
              </li>
            </ol>
          </Feedback>
          <div className="wink-form-field-container action-buttons">
            <button
              type="button"
              className="wink-button wink-button-secondary"
              onClick={() => retrySync()}
            >
              {retrying ? <SpinnerLabel label="Retry Sync" /> : <>Retry Sync</>}
            </button>
            <NavLink
              to="/sync-logs"
              className="wink-button wink-button-secondary"
            >
              View Sync Logs
            </NavLink>
          </div>
        </>
      )}
    </>
  );
};

LatestSync.propTypes = {
  getSync: PropTypes.func.isRequired,
  startPollingSync: PropTypes.func.isRequired,
  stopPollingSync: PropTypes.func.isRequired,
  startSync: PropTypes.func.isRequired,
  sync: SYNC_PROP_TYPES,
};

const mapDispatchToProps = {
  getSync,
  startPollingSync,
  stopPollingSync,
  startSync,
};

const mapStateToProps = createStructuredSelector({
  sync: simpleSelectorFor('sync'),
});

export default connect(mapStateToProps, mapDispatchToProps)(LatestSync);
