import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { simpleSelectorFor } from 'selectors/account-creation-selectors';
import {
  getMailchimpUsernameAvailability,
  getMailchimpUsernameSuggestion,
  getMailchimpSignupOptions,
  signup,
} from 'actions/account-creation-actions';
import {
  MAILCHIMP_USERNAME_AVAILABILITY_TYPE,
  MAILCHIMP_USERNAME_SUGGESTION_TYPE,
  SIGNUP_OPTIONS_TYPE,
  VALIDATION_ERROR_PROP_TYPES,
} from 'constants/proptypes';
import SpinnerLabel from 'components/spinner-label';
import TickCircleFillIcon from 'components/icons/tick-circle-fill-icon';
import IconLabel from 'components/icon-label';
import NotAllowedIcon from 'components/icons/not-allowed-icon';

const MailchimpAccountCreation = ({
  push,
  gettingMailchimpUsernameAvailability,
  getMailchimpUsernameAvailabilityError = null,
  mailchimpUsernameAvailability = null,
  getMailchimpUsernameAvailability,
  mailchimpUsernameSuggestion = null,
  getMailchimpUsernameSuggestion,
  getMailchimpSignupOptions,
  gettingMailchimpSignupOptions,
  mailchimpSignupOptions,
  signup,
  postingMailchimpSignup,
  postMailchimpSignupError = null,
}) => {
  const { register, handleSubmit, errors, getValues } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onTouched',
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const { countries, states } = mailchimpSignupOptions;
  const countriesWithStatesList = Object.keys(states).map((countryCode) =>
    countryCode.toUpperCase()
  );
  const [stateProvinceRequired, setStateProvinceRequired] = useState(false);
  const [postalCodeRequired, setPostalCodeRequired] = useState(false);
  const [statesList, setStatesList] = useState(null);

  const onSubmit = async (newAccountDetails, event) => {
    event.preventDefault();

    const signupSuccess = await signup({
      ...newAccountDetails,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    if (signupSuccess) push('/authenticate/mailchimp/success');
  };

  useEffect(() => {
    getMailchimpSignupOptions();
  }, []);

  const emailValidation = /^.{1,}@[^.]{1,}/;

  const fieldHasErrors = (field) =>
    errors[field] || postMailchimpSignupError?.errors?.fullMessages[field];
  const errorsForField = (field) =>
    postMailchimpSignupError?.errors?.messages[field]?.join(',') ||
    errors[field]?.message;

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h1>Create a Mailchimp Account</h1>

        <p>
          Already have a Mailchimp account? <a href="/auth/mailchimp">Log in</a>{' '}
          instead.
        </p>

        <h2>Profile</h2>

        <div className="wink-form-field-container">
          <div className="wink-form-label-container">
            <label
              className="wink-form-label"
              htmlFor="username"
              id="username-label"
            >
              Username
            </label>
            <span
              aria-describedby="username-label"
              className="wink-form-label-secondary"
            >
              Required
            </span>
          </div>
          <input
            name="username"
            className={`wink-form-field ${
              fieldHasErrors('username') ? 'wink-form-field-error' : ''
            }`}
            ref={register({
              required: 'Username is a required field.',
              pattern: {
                value: /^[A-Za-z0-9]*$/,
                message:
                  'Mailchimp usernames may only contain letters and numbers.',
              },
              validate: async () => {
                const username = getValues('username');
                getMailchimpUsernameAvailability(username);
                getMailchimpUsernameSuggestion(username);
              },
            })}
          />
          {gettingMailchimpUsernameAvailability && (
            <SpinnerLabel label="Checking username availability..." />
          )}
          {!fieldHasErrors('username') &&
            mailchimpUsernameAvailability?.accountAvailable && (
              <div className="wink-form-description">
                <IconLabel
                  icon={TickCircleFillIcon}
                  label="Username is available"
                />
              </div>
            )}
          {!fieldHasErrors('username') &&
            getMailchimpUsernameAvailabilityError && (
              <div className="wink-form-description wink-form-description-error">
                {getMailchimpUsernameAvailabilityError?.errors?.messages?.base?.map(
                  (error) => (
                    <div key={error}>{error}</div>
                  )
                )}
              </div>
            )}
          {!fieldHasErrors('username') &&
            !mailchimpUsernameAvailability?.accountAvailable &&
            mailchimpUsernameSuggestion?.usernames && (
              <div className="wink-form-description wink-form-description-error">
                <IconLabel
                  icon={NotAllowedIcon}
                  label="Username is not available"
                />
                Suggested usernames:{' '}
                {mailchimpUsernameSuggestion?.usernames.join(', ')}
              </div>
            )}
          <p
            aria-describedby="username-label"
            className="wink-form-description wink-form-description-error"
          >
            {errorsForField('username')}
          </p>
        </div>

        <div className="wink-form-field-container">
          <div className="wink-form-label-container">
            <label className="wink-form-label" htmlFor="email" id="email-label">
              Email
            </label>
            <span
              aria-describedby="email-label"
              className="wink-form-label-secondary"
            >
              Required
            </span>
          </div>
          <input
            name="email"
            className={`wink-form-field ${
              fieldHasErrors('email') ? 'wink-form-field-error' : ''
            }`}
            ref={register({
              required: 'Email is a required field.',
              validate: () =>
                emailValidation.test(getValues('email'))
                  ? true
                  : 'Please enter a valid email address.',
            })}
          />
          <p
            aria-describedby="email-label"
            className="wink-form-description wink-form-description-error"
          >
            {errorsForField('email')}
          </p>
        </div>

        <div className="wink-form-field-container">
          <div className="wink-form-label-container">
            <label
              className="wink-form-label"
              htmlFor="firstName"
              id="firstName-label"
            >
              First Name
            </label>
            <span
              aria-describedby="firstName-label"
              className="wink-form-label-secondary"
            >
              Required
            </span>
          </div>
          <input
            name="firstName"
            className={`wink-form-field ${
              fieldHasErrors('firstName') ? 'wink-form-field-error' : ''
            }`}
            ref={register({ required: 'First Name is a required field.' })}
          />
          <p
            aria-describedby="firstName-label"
            className="wink-form-description wink-form-description-error"
          >
            {errorsForField('firstName')}
          </p>
        </div>

        <div className="wink-form-field-container">
          <div className="wink-form-label-container">
            <label
              className="wink-form-label"
              htmlFor="lastName"
              id="lastName-label"
            >
              Last Name
            </label>
            <span
              aria-describedby="lastName-label"
              className="wink-form-label-secondary"
            >
              Required
            </span>
          </div>
          <input
            name="lastName"
            className={`wink-form-field ${
              fieldHasErrors('lastName') ? 'wink-form-field-error' : ''
            }`}
            ref={register({ required: 'Last Name is a required field.' })}
          />
          <p
            aria-describedby="lastName-label"
            className="wink-form-description wink-form-description-error"
          >
            {errorsForField('lastName')}
          </p>
        </div>

        <h2>Contact Information</h2>

        <div className="wink-form-field-container">
          <div className="wink-form-label-container">
            <label
              className="wink-form-label"
              htmlFor="organizationName"
              id="organizationName-label"
            >
              Organization Name
            </label>
            <span
              aria-describedby="organizationName-label"
              className="wink-form-label-secondary"
            >
              Required
            </span>
          </div>
          <input
            name="organizationName"
            className={`wink-form-field ${
              fieldHasErrors('organizationName') ? 'wink-form-field-error' : ''
            }`}
            ref={register({
              required: 'Organization Name is a required field.',
            })}
          />
          <p
            aria-describedby="organizationName-label"
            className="wink-form-description wink-form-description-error"
          >
            {errorsForField('organizationName')}
          </p>
        </div>

        <div className="wink-form-field-container">
          <div className="wink-form-label-container">
            <label
              className="wink-form-label"
              htmlFor="addressLine1"
              id="addressLine1-label"
            >
              Street Address 1
            </label>
            <span
              aria-describedby="addressLine1-label"
              className="wink-form-label-secondary"
            >
              Required
            </span>
          </div>
          <input
            name="addressLine1"
            className={`wink-form-field ${
              fieldHasErrors('addressLine1') ? 'wink-form-field-error' : ''
            }`}
            ref={register({
              required: 'Street Address 1 is a required field.',
            })}
          />
          <p
            aria-describedby="addressLine1-label"
            className="wink-form-description wink-form-description-error"
          >
            {errorsForField('addressLine1')}
          </p>
        </div>

        <div className="wink-form-field-container">
          <div className="wink-form-label-container">
            <label
              className="wink-form-label"
              htmlFor="addressLine2"
              id="addressLine2-label"
            >
              Street Address 2
            </label>
          </div>
          <input
            name="addressLine2"
            className={`wink-form-field ${
              fieldHasErrors('addressLine2') ? 'wink-form-field-error' : ''
            }`}
            ref={register()}
          />
          <p
            aria-describedby="addressLine2-label"
            className="wink-form-description wink-form-description-error"
          >
            {errorsForField('addressLine2')}
          </p>
        </div>

        <div className="wink-form-field-container">
          <div className="wink-form-label-container">
            <label className="wink-form-label" htmlFor="city" id="city-label">
              City
            </label>
            <span
              aria-describedby="city-label"
              className="wink-form-label-secondary"
            >
              Required
            </span>
          </div>
          <input
            name="city"
            className={`wink-form-field ${
              fieldHasErrors('city') ? 'wink-form-field-error' : ''
            }`}
            ref={register({
              required: 'City is a required field.',
            })}
          />
          <p
            aria-describedby="city-label"
            className="wink-form-description wink-form-description-error"
          >
            {errorsForField('city')}
          </p>
        </div>

        <div className="wink-form-field-container">
          <div className="wink-form-label-container">
            <label
              className="wink-form-label"
              htmlFor="country"
              id="country-label"
            >
              Country
            </label>
            <span
              aria-describedby="country-label"
              className="wink-form-label-secondary"
            >
              Required
            </span>
          </div>
          {gettingMailchimpSignupOptions ? (
            <SpinnerLabel label="Loading..." />
          ) : (
            <select
              name="country"
              className={`wink-form-select ${
                fieldHasErrors('country') ? 'wink-form-select-error' : ''
              }`}
              ref={register({ required: 'Country is a required field.' })}
              onChange={() => {
                const { country } = getValues();
                const { stateProvinceRequired, postalCodeRequired } =
                  countries.find((option) => option.code === country) || {};
                setStateProvinceRequired(stateProvinceRequired);
                setPostalCodeRequired(postalCodeRequired);
                setStatesList(states[country.toLowerCase()]);
              }}
            >
              <option value="" aria-label="Blank Option">
                &nbsp;
              </option>
              {countries.map((country) => (
                <option
                  key={`country-${country.code}-${country.name}`}
                  value={country.code}
                >
                  {country.name}
                </option>
              ))}
            </select>
          )}
          <p
            aria-describedby="country-label"
            className="wink-form-description wink-form-description-error"
          >
            {errorsForField('country')}
          </p>
        </div>

        <div className="wink-form-field-container">
          <div className="wink-form-label-container">
            <label className="wink-form-label" htmlFor="state" id="state-label">
              State/Province
            </label>
            {stateProvinceRequired && (
              <span
                aria-describedby="state-label"
                className="wink-form-label-secondary"
              >
                Required
              </span>
            )}
          </div>
          {countriesWithStatesList.indexOf(getValues().country) > -1 ? (
            <select
              name="state"
              className={`wink-form-select ${
                fieldHasErrors('state') ? 'wink-form-select-error' : ''
              }`}
              ref={register({
                required:
                  stateProvinceRequired &&
                  'State/Province is a required field.',
              })}
            >
              <option value="" aria-label="Blank Option">
                &nbsp;
              </option>
              {statesList.map((state) => (
                <option
                  key={`state-${state.abbreviation}`}
                  value={state.abbreviation}
                >
                  {state.name}
                </option>
              ))}
            </select>
          ) : (
            <input
              name="state"
              className={`wink-form-field ${
                fieldHasErrors('state') ? 'wink-form-field-error' : ''
              }`}
              ref={register({
                required:
                  stateProvinceRequired &&
                  'State/Province is a required field.',
              })}
            />
          )}
          <p
            aria-describedby="state-label"
            className="wink-form-description wink-form-description-error"
          >
            {errorsForField('state')}
          </p>
        </div>

        <div className="wink-form-field-container">
          <div className="wink-form-label-container">
            <label className="wink-form-label" htmlFor="zip" id="zip-label">
              Postal Code
            </label>
            {postalCodeRequired && (
              <span
                aria-describedby="zip-label"
                className="wink-form-label-secondary"
              >
                Required
              </span>
            )}
          </div>
          <input
            name="zip"
            className={`wink-form-field ${
              fieldHasErrors('zip') ? 'wink-form-field-error' : ''
            }`}
            ref={register({
              required:
                postalCodeRequired && 'Postal Code is a required field.',
            })}
          />
          <p
            aria-describedby="zip-label"
            className="wink-form-description wink-form-description-error"
          >
            {errorsForField('zip')}
          </p>
        </div>

        <div className="wink-form-field-container action-buttons">
          <button type="submit" className="wink-button wink-button-primary">
            {postingMailchimpSignup ? (
              <SpinnerLabel label="Create Free Account" />
            ) : (
              'Create Free Account'
            )}
          </button>
        </div>

        <p>
          By continuing, you are creating a Mailchimp account, and you agree to
          Mailchimp’s{' '}
          <a
            href="https://mailchimp.com/legal/terms/"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Use
          </a>{' '}
          and{' '}
          <a
            href="https://mailchimp.com/legal/privacy/"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          .
        </p>

        <p>
          &copy; 2001–2020 All Rights Reserved. Mailchimp&reg; is a registered
          trademark of The Rocket Science Group.{' '}
          <a
            href="https://mailchimp.com/legal/cookies/"
            target="_blank"
            rel="noreferrer"
          >
            Cookie Preferences
          </a>
          ,{' '}
          <a
            href="https://mailchimp.com/legal/privacy/"
            target="_blank"
            rel="noreferrer"
          >
            Privacy
          </a>
          , and{' '}
          <a
            href="https://mailchimp.com/legal/terms/"
            target="_blank"
            rel="noreferrer"
          >
            Terms
          </a>
          .
        </p>
      </form>
    </div>
  );
};

MailchimpAccountCreation.propTypes = {
  push: PropTypes.func.isRequired,
  gettingMailchimpUsernameAvailability: PropTypes.bool.isRequired,
  getMailchimpUsernameAvailabilityError: VALIDATION_ERROR_PROP_TYPES,
  mailchimpUsernameAvailability: MAILCHIMP_USERNAME_AVAILABILITY_TYPE,
  getMailchimpUsernameAvailability: PropTypes.func.isRequired,
  mailchimpUsernameSuggestion: MAILCHIMP_USERNAME_SUGGESTION_TYPE,
  getMailchimpUsernameSuggestion: PropTypes.func.isRequired,
  getMailchimpSignupOptions: PropTypes.func.isRequired,
  gettingMailchimpSignupOptions: PropTypes.bool.isRequired,
  mailchimpSignupOptions: SIGNUP_OPTIONS_TYPE.isRequired,
  postingMailchimpSignup: PropTypes.bool.isRequired,
  postMailchimpSignupError: VALIDATION_ERROR_PROP_TYPES,
  signup: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  push,
  getMailchimpUsernameAvailability,
  getMailchimpUsernameSuggestion,
  getMailchimpSignupOptions,
  signup,
};

const mapStateToProps = createStructuredSelector({
  gettingMailchimpUsernameAvailability: simpleSelectorFor(
    'gettingMailchimpUsernameAvailability'
  ),
  getMailchimpUsernameAvailabilityError: simpleSelectorFor(
    'getMailchimpUsernameAvailabilityError'
  ),
  mailchimpUsernameAvailability: simpleSelectorFor(
    'mailchimpUsernameAvailability'
  ),
  mailchimpUsernameSuggestion: simpleSelectorFor('mailchimpUsernameSuggestion'),
  gettingMailchimpSignupOptions: simpleSelectorFor(
    'gettingMailchimpSignupOptions'
  ),
  mailchimpSignupOptions: simpleSelectorFor('mailchimpSignupOptions'),
  postingMailchimpSignup: simpleSelectorFor('postingMailchimpSignup'),
  postMailchimpSignupError: simpleSelectorFor('postMailchimpSignupError'),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MailchimpAccountCreation);
