import React from 'react';
import PropTypes from 'prop-types';

const WarningCircleIcon = ({
  alt = 'Warning',
  className = '',
  size = 24,
  onClick = () => {},
}) => (
  <svg
    className={`wink-icon ${className}`}
    width={size}
    height={size}
    onClick={onClick}
    viewBox="0 0 24 24"
    aria-labelledby="WarningCircleIcon"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="WarningCircleIcon">{alt}</title>
    <path d="M13.5 17a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm-3-12l.5 9h2l.5-9h-3z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11zm0-2a9 9 0 100-18 9 9 0 000 18z"
    />
  </svg>
);

WarningCircleIcon.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
  onClick: PropTypes.func,
};

export default WarningCircleIcon;
