import { produce } from 'immer';
import {
  GET_MAILCHIMP_SIGNUP_OPTIONS_ERROR,
  GET_MAILCHIMP_SIGNUP_OPTIONS_START,
  GET_MAILCHIMP_SIGNUP_OPTIONS_SUCCESS,
  GET_MAILCHIMP_USERNAME_AVAILABILITY_ERROR,
  GET_MAILCHIMP_USERNAME_AVAILABILITY_START,
  GET_MAILCHIMP_USERNAME_AVAILABILITY_SUCCESS,
  GET_MAILCHIMP_USERNAME_SUGGESTION_ERROR,
  GET_MAILCHIMP_USERNAME_SUGGESTION_START,
  GET_MAILCHIMP_USERNAME_SUGGESTION_SUCCESS,
  POST_MAILCHIMP_SIGNUP_ERROR,
  POST_MAILCHIMP_SIGNUP_START,
  POST_MAILCHIMP_SIGNUP_SUCCESS,
  SET_MAILCHIMP_SIGNUP_OPTIONS_PATH,
  SET_MAILCHIMP_SIGNUPS_PATH,
  SET_MAILCHIMP_USERNAME_AVAILABILITY_PATH,
  SET_MAILCHIMP_USERNAME_SUGGESTION_PATH,
} from 'constants/action-types';

export const DEFAULT_SIGNUP_OPTIONS = { countries: [], states: {} };

export const INITIAL_STATE = {
  mailchimpUsernameAvailabilityPath: null,
  mailchimpUsernameSuggestionPath: null,
  mailchimpSignupOptionsPath: null,
  mailchimpSignupsPath: null,
  gettingMailchimpUsernameAvailability: false,
  getMailchimpUsernameAvailabilityError: null,
  mailchimpUsernameAvailability: null,
  gettingMailchimpUsernameSuggestion: false,
  getMailchimpUsernameSuggestionError: null,
  mailchimpUsernameSuggestion: null,
  gettingMailchimpSignupOptions: false,
  getMailchimpSignupOptionsError: null,
  mailchimpSignupOptions: DEFAULT_SIGNUP_OPTIONS,
  postingMailchimpSignup: false,
  postMailchimpSignupError: null,
};

function accountCreationReducer(state = INITIAL_STATE, { type, payload } = {}) {
  switch (type) {
    case SET_MAILCHIMP_USERNAME_AVAILABILITY_PATH:
      return produce(state, (draftState) => {
        draftState.mailchimpUsernameAvailabilityPath =
          payload.mailchimpUsernameAvailabilityPath;
      });
    case SET_MAILCHIMP_USERNAME_SUGGESTION_PATH:
      return produce(state, (draftState) => {
        draftState.mailchimpUsernameSuggestionPath =
          payload.mailchimpUsernameSuggestionPath;
      });
    case SET_MAILCHIMP_SIGNUP_OPTIONS_PATH:
      return produce(state, (draftState) => {
        draftState.mailchimpSignupOptionsPath =
          payload.mailchimpSignupOptionsPath;
      });
    case SET_MAILCHIMP_SIGNUPS_PATH:
      return produce(state, (draftState) => {
        draftState.mailchimpSignupsPath = payload.mailchimpSignupsPath;
      });
    case GET_MAILCHIMP_USERNAME_AVAILABILITY_START:
      return produce(state, (draftState) => {
        draftState.gettingMailchimpUsernameAvailability = true;
        draftState.getMailchimpUsernameAvailabilityError = null;
        draftState.mailchimpUsernameAvailability = null;
      });
    case GET_MAILCHIMP_USERNAME_AVAILABILITY_SUCCESS:
      return produce(state, (draftState) => {
        draftState.gettingMailchimpUsernameAvailability = false;
        draftState.getMailchimpUsernameAvailabilityError = null;
        draftState.mailchimpUsernameAvailability = payload;
      });
    case GET_MAILCHIMP_USERNAME_AVAILABILITY_ERROR:
      return produce(state, (draftState) => {
        draftState.gettingMailchimpUsernameAvailability = false;
        draftState.getMailchimpUsernameAvailabilityError =
          payload.getMailchimpUsernameAvailabilityError;
        draftState.mailchimpUsernameAvailability = null;
      });
    case GET_MAILCHIMP_USERNAME_SUGGESTION_START:
      return produce(state, (draftState) => {
        draftState.gettingMailchimpUsernameSuggestion = true;
        draftState.getMailchimpUsernameSuggestionError = null;
        draftState.mailchimpUsernameSuggestion = null;
      });
    case GET_MAILCHIMP_USERNAME_SUGGESTION_SUCCESS:
      return produce(state, (draftState) => {
        draftState.gettingMailchimpUsernameSuggestion = false;
        draftState.getMailchimpUsernameSuggestionError = null;
        draftState.mailchimpUsernameSuggestion = payload;
      });
    case GET_MAILCHIMP_USERNAME_SUGGESTION_ERROR:
      return produce(state, (draftState) => {
        draftState.gettingMailchimpUsernameSuggestion = false;
        draftState.getMailchimpUsernameSuggestionError =
          payload.getMailchimpUsernameSuggestionError;
        draftState.mailchimpUsernameSuggestion = null;
      });
    case GET_MAILCHIMP_SIGNUP_OPTIONS_START:
      return produce(state, (draftState) => {
        draftState.gettingMailchimpSignupOptions = true;
        draftState.getMailchimpSignupOptionsError = null;
        draftState.mailchimpSignupOptions = DEFAULT_SIGNUP_OPTIONS;
      });
    case GET_MAILCHIMP_SIGNUP_OPTIONS_SUCCESS:
      return produce(state, (draftState) => {
        draftState.gettingMailchimpSignupOptions = false;
        draftState.getMailchimpSignupOptionsError = null;
        draftState.mailchimpSignupOptions = payload;
      });
    case GET_MAILCHIMP_SIGNUP_OPTIONS_ERROR:
      return produce(state, (draftState) => {
        draftState.gettingMailchimpSignupOptions = false;
        draftState.getMailchimpSignupOptionsError =
          payload.getMailchimpSignupOptionsError;
        draftState.mailchimpSignupOptions = DEFAULT_SIGNUP_OPTIONS;
      });
    case POST_MAILCHIMP_SIGNUP_START:
      return produce(state, (draftState) => {
        draftState.postingMailchimpSignup = true;
        draftState.postMailchimpSignupError = null;
      });
    case POST_MAILCHIMP_SIGNUP_SUCCESS:
      return produce(state, (draftState) => {
        draftState.postingMailchimpSignup = false;
        draftState.postMailchimpSignupError = null;
      });
    case POST_MAILCHIMP_SIGNUP_ERROR:
      return produce(state, (draftState) => {
        draftState.postingMailchimpSignup = false;
        draftState.postMailchimpSignupError = payload.postMailchimpSignupError;
      });
    default:
      return state;
  }
}

export default accountCreationReducer;
