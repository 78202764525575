import axios from 'axios';
import {
  SET_DASHBOARD_ENABLEMENT_PATH,
  SET_SESSION_PATH,
  GET_SESSION_START,
  GET_SESSION_SUCCESS,
  GET_SESSION_ERROR,
  SET_AUTHENTICITY_TOKEN,
  SET_REMOTE_SERVICES_CONNECTION_PATH,
  DELETE_REMOTE_SERVICES_CONNECTION_START,
  DELETE_REMOTE_SERVICES_CONNECTION_SUCCESS,
  DELETE_REMOTE_SERVICES_CONNECTION_ERROR,
  SET_SYNC_NON_PHYSICAL_PRODUCTS_START,
  SET_SYNC_NON_PHYSICAL_PRODUCTS_SUCCESS,
  SET_SYNC_NON_PHYSICAL_PRODUCTS_ERROR,
  SET_SQUARESPACE_WEBSITE_SYNC_NONPHYSICAL_PRODUCTS_SETTING_PATH,
  POST_ENABLE_DASHBOARD_START,
  POST_ENABLE_DASHBOARD_ERROR,
  POST_ENABLE_DASHBOARD_SUCCESS,
} from 'constants/action-types';
import makeSerializable from '../util/makeSerializable';

const getEventName = (responseData) => {
  const { loginCount, justLoggedIn, justSignedUp } = responseData;
  let eventName = null;
  if (loginCount === 1 && justLoggedIn) {
    eventName = 'login';
  }
  if (justSignedUp) {
    eventName = 'sign-up';
  }
  return eventName;
};

const sendAnalyticsToGoogleTagManager = (responseData) => {
  const { dataLayer } = window;
  const event = getEventName(responseData);
  if (event) {
    dataLayer?.push({ event });
  }
};

export const setDashboardEnablementPath = (dashboardEnablementPath) => ({
  type: SET_DASHBOARD_ENABLEMENT_PATH,
  payload: { dashboardEnablementPath },
});

export const setSessionPath = (sessionPath) => ({
  type: SET_SESSION_PATH,
  payload: { sessionPath },
});

export const setSquarespaceWebsiteSyncNonphysicalProductSettingPath = (
  squarespaceWebsiteSyncNonphysicalProductSettingPath
) => ({
  type: SET_SQUARESPACE_WEBSITE_SYNC_NONPHYSICAL_PRODUCTS_SETTING_PATH,
  payload: { squarespaceWebsiteSyncNonphysicalProductSettingPath },
});

export const setRemoteServicesConnectionPath = (
  remoteServicesConnectionPath
) => ({
  type: SET_REMOTE_SERVICES_CONNECTION_PATH,
  payload: { remoteServicesConnectionPath },
});

export const setAuthenticityToken = (authenticityToken) => ({
  type: SET_AUTHENTICITY_TOKEN,
  payload: { authenticityToken },
});

export const loadSession = () => async (dispatch, getState) => {
  dispatch({
    type: GET_SESSION_START,
  });

  const { sessionPath } = getState().session;

  try {
    const response = await axios({
      method: 'get',
      url: sessionPath,
      validateStatus: (status) => status === 200,
    });

    sendAnalyticsToGoogleTagManager(response.data);

    dispatch({
      type: GET_SESSION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_SESSION_ERROR,
      payload: { loadSessionError: makeSerializable(error.response) },
    });
  }
};

export const setSyncNonphysicalProducts =
  (syncNonphysicalProducts) => async (dispatch, getState) => {
    dispatch({
      type: SET_SYNC_NON_PHYSICAL_PRODUCTS_START,
    });

    const {
      squarespaceWebsiteSyncNonphysicalProductSettingPath,
      authenticityToken,
    } = getState().session;

    try {
      await axios({
        method: 'put',
        url: squarespaceWebsiteSyncNonphysicalProductSettingPath,
        data: { sync_non_physical_products: syncNonphysicalProducts },
        headers: {
          'X-CSRF-Token': authenticityToken,
        },
        validateStatus: (status) => status === 200,
      });

      dispatch({
        type: SET_SYNC_NON_PHYSICAL_PRODUCTS_SUCCESS,
        payload: { syncNonphysicalProducts },
      });
    } catch (error) {
      dispatch({
        type: SET_SYNC_NON_PHYSICAL_PRODUCTS_ERROR,
        payload: {
          setSyncNonphysicalProductsError: makeSerializable(error.response),
        },
      });
    }
  };

export const disconnect = (deleteRemoteData) => async (dispatch, getState) => {
  dispatch({
    type: DELETE_REMOTE_SERVICES_CONNECTION_START,
  });

  const { remoteServicesConnectionPath, authenticityToken } =
    getState().session;

  try {
    await axios({
      method: 'delete',
      url: remoteServicesConnectionPath,
      data: { destroy_remote_data: deleteRemoteData },
      headers: {
        'X-CSRF-Token': authenticityToken,
      },
      validateStatus: (status) => status === 204,
    });

    await dispatch({
      type: DELETE_REMOTE_SERVICES_CONNECTION_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: DELETE_REMOTE_SERVICES_CONNECTION_ERROR,
      payload: {
        deleteRemoteServicesConnectionError: makeSerializable(error.response),
      },
    });
  }
};

export const enableDashboard = () => async (dispatch, getState) => {
  dispatch({
    type: POST_ENABLE_DASHBOARD_START,
  });

  const { dashboardEnablementPath, authenticityToken } = getState().session;

  try {
    await axios({
      method: 'post',
      url: dashboardEnablementPath,
      headers: {
        'X-CSRF-Token': authenticityToken,
      },
      validateStatus: (status) => status === 204,
    });

    dispatch({
      type: POST_ENABLE_DASHBOARD_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: POST_ENABLE_DASHBOARD_ERROR,
      payload: { postEnableDashboardError: makeSerializable(error.response) },
    });
  }
};
