import React from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { simpleSelectorFor } from 'selectors/session-selectors';
import {
  MAILCHIMP_USER_PROP_TYPES,
  SQUARESPACE_WEBSITE_PROP_TYPES,
} from 'constants/proptypes';
import SquarespaceLogo from './squarespace-logo';
import TickCircleFillIcon from './icons/tick-circle-fill-icon';
import MailchimpLogo from './mailchimp-logo';

const MailchimpLogin = ({
  squarespaceWebsite = null,
  mailchimpUser = null,
  push,
  authenticityToken,
}) => (
  <>
    <h1>Login to Mailchimp</h1>

    <p>Welcome to the Squarespace Commerce Integration tool for Mailchimp.</p>
    <p>Please login with Mailchimp to continue.</p>

    <div className="login-status">
      <div className="service-name">
        <SquarespaceLogo />
        <div className="wink-h3">Squarespace</div>
      </div>
      <div className="icon-label">
        <TickCircleFillIcon className="" />
        {squarespaceWebsite.title} Authorized
      </div>
    </div>
    <div className="login-status">
      <div className="service-name">
        <MailchimpLogo />
        <div className="wink-h3">Mailchimp</div>
      </div>

      {mailchimpUser?.authorized ? (
        <div className="auth-status">
          <div className="icon-label">
            <TickCircleFillIcon className="" />
            {mailchimpUser.firstName} {mailchimpUser.lastName} Authorized
          </div>

          <form action="/auth/mailchimp" method="post">
            <input
              type="hidden"
              name="authenticity_token"
              value={authenticityToken}
            />
            <button type="submit" className="wink-button wink-button-secondary">
              Login With a Different Mailchimp Account
            </button>
          </form>
          <p className="create-account-link">
            <button
              type="button"
              className="link"
              onClick={() => {
                push('/authenticate/mailchimp/create');
              }}
            >
              Create new Mailchimp account
            </button>
          </p>
        </div>
      ) : (
        <div className="auth-status">
          <form action="/auth/mailchimp" method="post">
            <input
              type="hidden"
              name="authenticity_token"
              value={authenticityToken}
            />
            <button type="submit" className="wink-button wink-button-primary">
              Log in with Mailchimp
            </button>
          </form>

          <p className="create-account-link">
            Don&apos;t have an account?{' '}
            <button
              type="button"
              className="link"
              onClick={() => {
                push('/authenticate/mailchimp/create');
              }}
            >
              Create one
            </button>{' '}
            instead
          </p>
        </div>
      )}
    </div>
    <form>
      <div className="wink-form-field-container action-buttons">
        <button
          type="button"
          className="wink-button wink-button-primary"
          onClick={() => push('/settings')}
          disabled={!mailchimpUser?.authorized}
        >
          Next: Settings
        </button>
      </div>
    </form>
  </>
);

MailchimpLogin.propTypes = {
  squarespaceWebsite: SQUARESPACE_WEBSITE_PROP_TYPES,
  mailchimpUser: MAILCHIMP_USER_PROP_TYPES,
  push: PropTypes.func.isRequired,
  authenticityToken: PropTypes.string.isRequired,
};

const mapDispatchToProps = { push };

const mapStateToProps = createStructuredSelector({
  squarespaceWebsite: simpleSelectorFor('squarespaceWebsite'),
  mailchimpUser: simpleSelectorFor('mailchimpUser'),
  authenticityToken: simpleSelectorFor('authenticityToken'),
});

export default connect(mapStateToProps, mapDispatchToProps)(MailchimpLogin);
