import React from 'react';
import PropTypes from 'prop-types';

const HamburgerIcon = ({
  alt = 'Hamburger',
  className = '',
  size = 24,
  onClick = () => {},
}) => (
  <svg
    className={`wink-icon ${className}`}
    width={size}
    height={size}
    onClick={onClick}
    viewBox="0 0 24 24"
    aria-labelledby="HamburgerIcon"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="HamburgerIcon">{alt}</title>
    <path d="M22 7H2V5h20v2zm0 6H2v-2h20v2zM2 19h20v-2H2v2z" />
  </svg>
);

HamburgerIcon.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
  onClick: PropTypes.func,
};

export default HamburgerIcon;
