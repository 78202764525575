import React from 'react';
import PropTypes from 'prop-types';
import TickCircleIcon from './icons/tick-circle-icon';
import CrossCircleIcon from './icons/cross-circle-icon';
import WarningCircleIcon from './icons/warning-circle-icon';

const Feedback = ({ status, label, icon: Icon = null, children = null }) => (
  <div className={`wink-feedback wink-feedback-${status}`}>
    {Icon ? (
      <Icon className="wink-icon wink-feedback-icon" />
    ) : (
      <>
        {status === 'success' && (
          <TickCircleIcon className="wink-icon wink-feedback-icon" />
        )}
        {status === 'warning' && (
          <WarningCircleIcon className="wink-icon wink-feedback-icon" />
        )}
        {status === 'error' && (
          <CrossCircleIcon className="wink-icon wink-feedback-icon" />
        )}
      </>
    )}
    <div className="wink-feedback-message">
      <h2 className="wink-text-tag">{label}</h2>
      <div className="wink-text-small">{children}</div>
    </div>
  </div>
);

Feedback.propTypes = {
  status: PropTypes.oneOf(['success', 'warning', 'error']),
  label: PropTypes.string.isRequired,
  icon: PropTypes.elementType,
  children: PropTypes.node,
};

export default Feedback;
