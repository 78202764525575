import React from 'react';
import PropTypes from 'prop-types';
import SpinnerLabel from './spinner-label';

const Loading = ({ label = 'Loading...' }) => (
  <main className="loading">
    <SpinnerLabel label={label} />
  </main>
);

Loading.propTypes = {
  label: PropTypes.string,
};

export default Loading;
