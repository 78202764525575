import axios from "axios";
import {
  SET_LISTS_PATH,
  GET_LISTS_START,
  GET_LISTS_SUCCESS,
  GET_LISTS_ERROR,
  CREATE_LIST_START,
  CREATE_LIST_ERROR,
  SET_SELECTED_LIST_ID,
  CREATE_LIST_SUCCESS,
  SAVE_SELECTED_LIST_START,
  SAVE_SELECTED_LIST_ERROR,
  SAVE_SELECTED_LIST_SUCCESS,
  SET_MAILCHIMP_USER_LIST_PATH,
  RESET_CREATE_LIST_ERROR,
} from "constants/action-types";

export const setMailchimpListsPath = (mailchimpListsPath) => ({
  type: SET_LISTS_PATH,
  payload: { mailchimpListsPath },
});

export const setSelectedListId = (selectedListId) => ({
  type: SET_SELECTED_LIST_ID,
  payload: { selectedListId },
});

export const loadLists = () => async (dispatch, getState) => {
  dispatch({
    type: GET_LISTS_START,
  });

  const { mailchimpListsPath } = getState().lists;

  try {
    const response = await axios({
      method: "get",
      url: mailchimpListsPath,
      validateStatus: (status) => status === 200,
    });
    dispatch({
      type: GET_LISTS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    if (error.response.status === 401) {
      window.location.href = "/";
    } else {
      dispatch({
        type: GET_LISTS_ERROR,
        payload: { loadListsError: error.response.data },
      });
    }
  }
};

export const createNewList = (
  listName,
  enableDoubleOptin,
  permissionReminder,
  fromName
) => async (dispatch, getState) => {
  dispatch({
    type: CREATE_LIST_START,
  });

  const {
    lists: { mailchimpListsPath },
    session: { authenticityToken },
  } = getState();

  try {
    const response = await axios({
      method: "post",
      url: mailchimpListsPath,
      data: {
        name: listName,
        double_optin: enableDoubleOptin,
        permission_reminder: permissionReminder,
        from_name: fromName,
      },
      headers: {
        "X-CSRF-Token": authenticityToken,
      },
      validateStatus: (status) => status === 200,
    });

    dispatch({
      type: CREATE_LIST_SUCCESS,
      payload: response.data,
    });
    dispatch(loadLists());
  } catch (error) {
    if (error.response.status === 401) {
      window.location.href = "/";
    } else {
      dispatch({
        type: CREATE_LIST_ERROR,
        payload: { createListError: error.response.data },
      });
    }
  }
};

export const resetCreateListError = () => ({
  type: RESET_CREATE_LIST_ERROR,
});

export const setMailchimpUserListPath = (mailchimpUserListPath) => ({
  type: SET_MAILCHIMP_USER_LIST_PATH,
  payload: { mailchimpUserListPath },
});

export const saveSelectedList = (selectedList) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: SAVE_SELECTED_LIST_START,
  });

  const {
    lists: { mailchimpUserListPath },
    session: { authenticityToken },
  } = getState();

  try {
    await axios({
      method: "put",
      url: mailchimpUserListPath,
      data: {
        remote_list_id: selectedList.remoteListId,
        name: selectedList.name,
      },
      headers: {
        "X-CSRF-Token": authenticityToken,
      },
      validateStatus: (status) => status === 200,
    });

    await dispatch({
      type: SAVE_SELECTED_LIST_SUCCESS,
      payload: selectedList,
    });
  } catch (error) {
    if (error.response.status === 401) {
      window.location.href = "/";
    } else {
      dispatch({
        type: SAVE_SELECTED_LIST_ERROR,
        payload: { saveSelectedListError: error.response.data },
      });
    }
  }
};
