import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  MAILCHIMP_USER_PROP_TYPES,
  SQUARESPACE_WEBSITE_PROP_TYPES,
} from 'constants/proptypes';
import { simpleSelectorFor as simpleSessionSelectorFor } from 'selectors/session-selectors';
import LatestSync from './latest-sync';

const Dashboard = (props) => {
  const { squarespaceWebsite, mailchimpUser } = props;

  return (
    <div>
      <h1>Welcome Back</h1>

      <table className="table-list">
        <tbody>
          <tr>
            <th>Syncing Store:</th>
            <td>
              <a href={squarespaceWebsite.url} target="_blank" rel="noreferrer">
                {squarespaceWebsite.title}
              </a>
            </td>
          </tr>
          <tr>
            <th>Syncing to list:</th>
            <td>{mailchimpUser.listName}</td>
          </tr>
          <tr>
            <th>Syncs run every:</th>
            <td>{squarespaceWebsite.syncInterval}</td>
          </tr>
          <tr>
            <th>Next sync starts:</th>
            <td>{squarespaceWebsite.nextSyncIn} from now</td>
          </tr>
        </tbody>
      </table>

      <LatestSync />
    </div>
  );
};

Dashboard.propTypes = {
  squarespaceWebsite: SQUARESPACE_WEBSITE_PROP_TYPES.isRequired,
  mailchimpUser: MAILCHIMP_USER_PROP_TYPES.isRequired,
};

const mapDispatchToProps = {};

const mapStateToProps = createStructuredSelector({
  squarespaceWebsite: simpleSessionSelectorFor('squarespaceWebsite'),
  mailchimpUser: simpleSessionSelectorFor('mailchimpUser'),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
