import React, { useEffect, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable/dist/react-select.esm';
import Select from 'react-select';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import NotAllowedIcon from 'components/icons/not-allowed-icon';
import TickCircleIcon from 'components/icons/tick-circle-icon';
import Loading from 'components/loading';
import creatableSelectStyles from 'constants/creatable-select-styles';
import {
  ERROR_PROP_TYPES,
  MAILCHIMP_LIST_PROP_TYPES,
  VALIDATION_ERROR_PROP_TYPES,
} from 'constants/proptypes';
import {
  createNewList,
  loadLists,
  resetCreateListError,
  setSelectedListId,
} from 'actions/lists-actions';
import { simpleSelectorFor } from 'selectors/lists-selectors';
import { selectMailchimpUserRemoteListId } from 'selectors/session-selectors';
import SpinnerLabel from 'components/spinner-label';
import Modal from 'components/modal';

const toSelectObject = (list) => ({
  value: list?.id,
  label: list?.name,
});

const MailchimpList = ({
  loadLists,
  loadListsError = null,
  createdNewList,
  loadingLists,
  canCreateList,
  creatingNewList,
  lists = null,
  mailchimpUserRemoteListId = null,
  setSelectedListId,
  selectedListId = null,
  createListError = null,
  createNewList,
  resetCreateListError,
}) => {
  const [showConfigDialog, setShowConfigDialog] = useState(false);
  const [newListName, setNewListName] = useState('');
  const [enableDoubleOptin, setEnableDoubleOptin] = useState(false);
  const [permissionReminder, setPermissionReminder] = useState('');
  const [fromName, setFromName] = useState('');
  const fromNameElement = useRef(null);

  const selectList = (list) => {
    if (list === null) return;

    // eslint-disable-next-line no-underscore-dangle
    if (list.__isNew__) {
      setShowConfigDialog(true);
      setNewListName(list.label);
    } else {
      setSelectedListId(list.value);
    }
  };

  useEffect(() => {
    fromNameElement?.current?.focus();
  }, [showConfigDialog]);

  const cancelNewList = () => {
    setShowConfigDialog(false);
    setEnableDoubleOptin(false);
    setPermissionReminder('');
    setFromName('');
    resetCreateListError();
  };

  const Selector = useMemo(
    () => (canCreateList ? CreatableSelect : Select),
    [canCreateList]
  );

  useEffect(() => {
    loadLists();
    if (mailchimpUserRemoteListId) {
      setSelectedListId(mailchimpUserRemoteListId);
    }
  }, []);

  useEffect(() => {
    setShowConfigDialog(false);
    setNewListName('');
    setPermissionReminder('');
    setEnableDoubleOptin(false);
  }, [lists]);

  const selectedList = useMemo(
    () => toSelectObject(lists.find((list) => list?.id === selectedListId)),
    [selectedListId, lists]
  );

  const erroredFields = useMemo(() => {
    if (createListError?.errors?.fullMessages) {
      return Object.keys(createListError?.errors?.fullMessages);
    }
    return [];
  }, [createListError]);

  return (
    <>
      {loadListsError && (
        <div className="wink-feedback wink-feedback-error">
          <NotAllowedIcon className="wink-feedback-icon" />
          <div className="wink-feedback-message">
            <h2 className="wink-text-tag">
              We couldn&apos;t load your Mailchimp lists.
            </h2>
            <p className="wink-text-small">
              There was an unexpected error loading your mailchimp lists.
            </p>
          </div>
        </div>
      )}

      {createdNewList && (
        <div className="wink-feedback wink-feedback-success">
          <TickCircleIcon className="wink-feedback-icon" />
          <div className="wink-feedback-message">
            <h2 className="wink-text-tag">Your new list has been created!</h2>
          </div>
        </div>
      )}

      <div className="wink-form-field-container">
        <div className="wink-form-label-container">
          <label
            className="wink-form-label"
            htmlFor="mailchimp-list"
            id="mailchimp-list-label"
          >
            Mailchimp List to Use
          </label>
          <span
            aria-describedby="mailchimp-list-label"
            className="wink-form-label-secondary"
          >
            Required
          </span>
        </div>
        {loadingLists && <Loading label="Loading your lists..." />}
        {!loadingLists && (
          <>
            <Selector
              isClearable
              isSearchable={canCreateList}
              styles={creatableSelectStyles}
              onChange={selectList}
              value={selectedList}
              isDisabled={loadingLists || creatingNewList}
              options={lists.map((list) => ({
                value: list.id,
                label: list.name,
              }))}
              id="mailchimp-list"
            />
            {canCreateList && (
              <p
                aria-describedby="mailchimp-list-label"
                className="wink-form-description"
              >
                Hint: Don’t see a list you want to use? Just enter a name for a
                new list and we’ll create it for you!
              </p>
            )}
          </>
        )}
      </div>
      <Modal isOpen={showConfigDialog}>
        <h3>Settings for &quot;{newListName}&quot;</h3>

        {createListError && (
          <div
            className="wink-feedback wink-feedback-error"
            style={{ marginTop: 'var(--spacing-4)' }}
          >
            <NotAllowedIcon className="wink-feedback-icon" />
            <div className="wink-feedback-message">
              <h2 className="wink-text-tag">{createListError.title}</h2>
              <p className="wink-text-small">{createListError.detail}</p>
              <ul className="wink-text-small">
                {Object.keys(createListError.errors.fullMessages).map((key) => (
                  <li key={key}>
                    {createListError.errors.fullMessages[key].join(', ')}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}

        <form onSubmit={(e) => e.preventDefault()}>
          <fieldset>
            <div className="wink-form-field-container">
              <div className="wink-form-label-container">
                <label
                  className="wink-form-label"
                  htmlFor="from-name"
                  id="from-name-label"
                >
                  From Name
                </label>
                <span
                  aria-describedby="from-name-label"
                  className="wink-form-label-secondary"
                >
                  Required
                </span>
              </div>
              <input
                className={`wink-form-field ${
                  erroredFields.includes('fromName')
                    ? 'wink-form-field-error'
                    : ''
                }`}
                id="from-name"
                type="text"
                maxLength="100"
                onChange={(e) => setFromName(e.target.value)}
                value={fromName}
                ref={fromNameElement}
              />
              <p
                aria-describedby="from-name-label"
                className={`wink-form-description ${
                  erroredFields.includes('fromName')
                    ? 'wink-form-description-error'
                    : ''
                }`}
              >
                This is the name your emails will come from. Use something your
                subscribers will instantly recognize, like your company name.
              </p>
            </div>
            <div className="wink-form-field-container">
              <div className="wink-form-label-container">
                <label
                  className="wink-form-label"
                  htmlFor="permission-reminder"
                  id="permission-reminder-label"
                >
                  Permission Reminder
                </label>
                <span
                  aria-describedby="permission-reminder-label"
                  className="wink-form-label-secondary"
                >
                  Required
                </span>
              </div>
              <input
                className={`wink-form-field ${
                  erroredFields.includes('permissionReminder')
                    ? 'wink-form-field-error'
                    : ''
                }`}
                id="permission-reminder1"
                type="text"
                onChange={(e) => setPermissionReminder(e.target.value)}
                value={permissionReminder}
              />
              <p
                aria-describedby="permission-reminder-label"
                className={`wink-form-description ${
                  erroredFields.includes('permissionReminder')
                    ? 'wink-form-description-error'
                    : ''
                }`}
              >
                Remind recipients how they signed up to your list. This will
                appear in the footer of emails sent to the list and can be
                changed on the Mailchimp website. For example, &quot;We send
                special offers to customers who purchase from our website.&quot;
              </p>
            </div>
            <div className="wink-form-control-container">
              <label className="wink-form-control-label" id="double-opt-in">
                <input
                  type="checkbox"
                  checked={enableDoubleOptin}
                  onChange={() => setEnableDoubleOptin(!enableDoubleOptin)}
                  className="wink-form-control wink-form-control-checkbox"
                />
                Enable double opt-in
              </label>
              <p
                aria-describedby="double-opt-in"
                className="wink-form-description"
              >
                Sent contacts an opt-in confirmation email when they subscribe
                to your audience.
              </p>
            </div>
          </fieldset>
          <div className="wink-form-field-container action-buttons">
            <button
              type="button"
              className="wink-button wink-button-secondary"
              onClick={() => cancelNewList()}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="wink-button wink-button-primary"
              onClick={() => {
                if (creatingNewList) return;

                createNewList(
                  newListName,
                  enableDoubleOptin,
                  permissionReminder,
                  fromName
                );
              }}
            >
              {creatingNewList ? (
                <SpinnerLabel label="Create List" />
              ) : (
                <>Create List</>
              )}
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

MailchimpList.propTypes = {
  loadLists: PropTypes.func.isRequired,
  loadListsError: ERROR_PROP_TYPES,
  createdNewList: PropTypes.bool.isRequired,
  loadingLists: PropTypes.bool.isRequired,
  canCreateList: PropTypes.bool.isRequired,
  createNewList: PropTypes.func.isRequired,
  lists: PropTypes.arrayOf(MAILCHIMP_LIST_PROP_TYPES),
  mailchimpUserRemoteListId: PropTypes.string,
  setSelectedListId: PropTypes.func.isRequired,
  selectedListId: PropTypes.string,
  creatingNewList: PropTypes.bool.isRequired,
  createListError: VALIDATION_ERROR_PROP_TYPES,
  resetCreateListError: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  loadLists,
  createNewList,
  setSelectedListId,
  resetCreateListError,
};

const mapStateToProps = createStructuredSelector({
  loadingLists: simpleSelectorFor('loadingLists'),
  lists: simpleSelectorFor('lists'),
  canCreateList: simpleSelectorFor('canCreateList'),
  loadListsError: simpleSelectorFor('loadListsError'),
  selectedListId: simpleSelectorFor('selectedListId'),
  mailchimpUserRemoteListId: selectMailchimpUserRemoteListId,
  creatingNewList: simpleSelectorFor('creatingNewList'),
  createListError: simpleSelectorFor('createListError'),
  createdNewList: simpleSelectorFor('createdNewList'),
});

export default connect(mapStateToProps, mapDispatchToProps)(MailchimpList);
