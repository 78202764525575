import React from 'react';
import PropTypes from 'prop-types';
import Spinner from './spinner';

const SpinnerLabel = ({ label, className = '' }) => (
  <div className={`spinner-label ${className}`}>
    <Spinner />
    {label}
  </div>
);

SpinnerLabel.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default SpinnerLabel;
