import React from 'react';
import PropTypes from 'prop-types';
import TickCircleIcon from './icons/tick-circle-icon';
import Spinner from './spinner';
import CrossCircleIcon from './icons/cross-circle-icon';

const SyncObjectTypeStatus = ({ status = null, objectType, count = null }) => {
  if (status === null || count === null) return null;

  const label = count === 1 ? objectType : `${objectType}s`;

  return (
    <div>
      {(status === 'in_progress' || status === 'retrying') && (
        <Spinner size="1em" />
      )}
      {status === 'succeeded' && <TickCircleIcon />}
      {status === 'failed' && <CrossCircleIcon />} Synced {count} {label}.
    </div>
  );
};

SyncObjectTypeStatus.propTypes = {
  objectType: PropTypes.string.isRequired,
  status: PropTypes.string,
  count: PropTypes.number,
};

export default SyncObjectTypeStatus;
