import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router';
import { NavLink } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import axios from 'axios';
import Header from 'components/header';
import HomeIcon from 'components/icons/home-icon';
import SettingsIcon from 'components/icons/settings-icon';
import InfoCircleIcon from 'components/icons/info-circle-icon';
import ConversationIcon from 'components/icons/conversation-icon';
import IconLabel from 'components/icon-label';
import ExitIcon from 'components/icons/exit-icon';
import { simpleSelectorFor } from 'selectors/session-selectors';
import HamburgerIcon from 'components/icons/hamburger-icon';
import Settings from './dashboard/settings';
import SyncLogs from './dashboard/sync-logs';
import Support from './dashboard/support';
import Dashboard from './dashboard/dashboard';
import UserIdsDisplayContainer from './user-ids-display-container';

const DashboardContainer = (props) => {
  const { sessionPath, authenticityToken } = props;

  const logout = async () => {
    await axios({
      method: 'delete',
      url: sessionPath,

      headers: {
        'X-CSRF-Token': authenticityToken,
      },
      validateStatus: (status) => status === 204,
    });

    window.location.href = '/';
  };

  const [showMenu, setShowMenu] = useState(false);

  return (
    <div className="dashboard-container">
      <div className="dashboard-header">
        <div className="dashboard-sidebar wink-h4 wink-nomargin">
          Mailchimp + Squarespace Commerce Integration
        </div>

        <HamburgerIcon
          className="hamburger"
          onClick={() => setShowMenu(!showMenu)}
        />
        <Header>
          <div className="small-label">
            Mailchimp + Squarespace Commerce Integration
          </div>
        </Header>
      </div>
      <div className="dashboard-content">
        <nav className={`dashboard-sidebar ${showMenu ? 'show-menu' : ''}`}>
          <ul>
            <li>
              <NavLink
                to="/"
                className="wink-button"
                activeClassName="active"
                exact
              >
                <IconLabel label="Dashboard" icon={HomeIcon} />
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/settings"
                className="wink-button"
                activeClassName="active"
                exact
              >
                <IconLabel label="Settings" icon={SettingsIcon} />
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/sync-logs"
                className="wink-button"
                activeClassName="active"
                exact
              >
                <IconLabel label="Sync Logs" icon={InfoCircleIcon} />
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/support"
                className="wink-button"
                activeClassName="active"
                exact
              >
                <IconLabel label="Support" icon={ConversationIcon} />
              </NavLink>
            </li>
            <li>
              <form>
                <button
                  type="button"
                  onClick={() => logout()}
                  className="wink-button wink-button-tertiary"
                >
                  <IconLabel label="Log Out" icon={ExitIcon} />
                </button>
              </form>
            </li>
          </ul>
        </nav>
        <main>
          <Switch>
            <Route exact path="/settings">
              <Settings />
            </Route>
            <Route exact path="/sync-logs">
              <SyncLogs />
            </Route>
            <Route exact path="/support">
              <Support />
            </Route>
            <Route exact path="/">
              <Dashboard />
            </Route>
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </Switch>
          <UserIdsDisplayContainer />
        </main>
      </div>
    </div>
  );
};

DashboardContainer.propTypes = {
  sessionPath: PropTypes.string.isRequired,
  authenticityToken: PropTypes.string.isRequired,
};

const mapDispatchToProps = {};

const mapStateToProps = createStructuredSelector({
  sessionPath: simpleSelectorFor('sessionPath'),
  authenticityToken: simpleSelectorFor('authenticityToken'),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
