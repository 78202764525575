import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { simpleSelectorFor } from 'selectors/session-selectors';
import {
  MAILCHIMP_USER_PROP_TYPES,
  SQUARESPACE_WEBSITE_PROP_TYPES,
} from 'constants/proptypes';

const ErrorBoundary = ({
  children,
  bugsnagApiKey = null,
  bugsnagReleaseStage,
  revision = null,
  squarespaceWebsite = null,
  mailchimpUser = null,
}) => {
  if (!bugsnagApiKey || !bugsnagReleaseStage || !revision) return children;

  useMemo(() => {
    Bugsnag.start({
      apiKey: bugsnagApiKey,
      appType: 'client',
      appVersion: revision,
      plugins: [new BugsnagPluginReact()],
      releaseStage: bugsnagReleaseStage,
    });
  }, []);

  useMemo(() => {
    Bugsnag.addMetadata('squarespaceWebsite', squarespaceWebsite);
    Bugsnag.addMetadata('mailchimpUser', mailchimpUser);
  }, [squarespaceWebsite, mailchimpUser]);

  const Boundary = useMemo(
    () => Bugsnag.getPlugin('react').createErrorBoundary(React),
    []
  );

  return <Boundary>{children}</Boundary>;
};

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  bugsnagApiKey: PropTypes.string,
  bugsnagReleaseStage: PropTypes.string.isRequired,
  revision: PropTypes.string,
  squarespaceWebsite: SQUARESPACE_WEBSITE_PROP_TYPES,
  mailchimpUser: MAILCHIMP_USER_PROP_TYPES,
};

const mapDispatchToProps = {};

const mapStateToProps = createStructuredSelector({
  squarespaceWebsite: simpleSelectorFor('squarespaceWebsite'),
  mailchimpUser: simpleSelectorFor('mailchimpUser'),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
