import axios from 'axios';
import {
  START_SYNC_START,
  START_SYNC_SUCCESS,
  START_SYNC_ERROR,
  SET_SYNCS_PATH,
  SET_SYNC_PATH,
  GET_SYNC_START,
  GET_SYNC_SUCCESS,
  GET_SYNC_ERROR,
  SET_SQUARESPACE_WEBHOOK_SUBSCRIPTIONS_PATH,
  SET_SYNC_POLLING_INTERVAL_ID,
  GET_SYNCS_START,
  GET_SYNCS_SUCCESS,
  GET_SYNCS_ERROR,
  SET_SYNC_POLLING_FREQUENCY,
} from 'constants/action-types';
import { loadSession } from './session-actions';

export const setSyncsPath = (syncsPath) => ({
  type: SET_SYNCS_PATH,
  payload: { syncsPath },
});

export const setSyncPath = (syncPath) => ({
  type: SET_SYNC_PATH,
  payload: { syncPath },
});

export const setSquarespaceWebhookSubscriptionsPath = (
  squarespaceWebhookSubscriptionsPath
) => ({
  type: SET_SQUARESPACE_WEBHOOK_SUBSCRIPTIONS_PATH,
  payload: { squarespaceWebhookSubscriptionsPath },
});

export const getSync = (syncId) => async (dispatch, getState) => {
  const {
    syncs: { syncPath, gettingSync },
  } = getState();

  if (gettingSync) return;

  dispatch({
    type: GET_SYNC_START,
  });

  try {
    const response = await axios({
      method: 'get',
      url: syncPath.replace(':id', syncId),
      validateStatus: (status) => status === 200,
    });

    dispatch({
      type: GET_SYNC_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    if (error.response.status === 401) {
      window.location.href = '/';
    } else {
      dispatch({
        type: GET_SYNC_ERROR,
        payload: { getSyncError: error.response.data },
      });
    }
  }
};

export const startPollingSync = (syncId) => async (dispatch, getState) => {
  const {
    syncs: { syncPollingIntervalId, sync },
  } = getState();

  if (syncPollingIntervalId) return;

  // eslint-disable-next-line no-use-before-define
  const pollingFrequency = correctPollingFrequency(sync?.status);

  dispatch({
    type: SET_SYNC_POLLING_FREQUENCY,
    payload: { syncPollingFrequency: pollingFrequency },
  });

  const newIntervalId = setInterval(async () => {
    await dispatch(getSync(syncId));
    await dispatch(loadSession());

    // eslint-disable-next-line no-use-before-define
    await dispatch(throttlePolling(syncId));
  }, pollingFrequency);

  dispatch({
    type: SET_SYNC_POLLING_INTERVAL_ID,
    payload: { syncPollingIntervalId: newIntervalId },
  });
};

export const stopPollingSync = () => async (dispatch, getState) => {
  const {
    syncs: { syncPollingIntervalId },
  } = getState();

  clearInterval(syncPollingIntervalId);

  await dispatch({
    type: SET_SYNC_POLLING_INTERVAL_ID,
    payload: { syncPollingIntervalId: null },
  });
};

const correctPollingFrequency = (status) =>
  status === 'in_progress' || status === 'retrying' ? 1500 : 5000;

const throttlePolling = (syncId) => async (dispatch, getState) => {
  const {
    syncs: { syncPollingFrequency, sync },
  } = getState();

  const newPollingFrequency = correctPollingFrequency(sync?.status);

  if (syncPollingFrequency !== newPollingFrequency) {
    dispatch(stopPollingSync());
    dispatch(startPollingSync(syncId));
  }
};

export const startSync =
  (initial = false, full = true) =>
  async (dispatch, getState) => {
    dispatch({
      type: START_SYNC_START,
    });

    const {
      syncs: { syncsPath, squarespaceWebhookSubscriptionsPath },
      session: { authenticityToken },
    } = getState();

    try {
      const response = await axios({
        method: 'post',
        url: syncsPath,
        params: { initial, full },
        headers: {
          'X-CSRF-Token': authenticityToken,
        },
        validateStatus: (status) => status === 200,
      });

      dispatch({
        type: START_SYNC_SUCCESS,
        payload: response.data,
      });

      await axios({
        method: 'post',
        url: squarespaceWebhookSubscriptionsPath,
        headers: {
          'X-CSRF-Token': authenticityToken,
        },
        validateStatus: (status) => status === 200,
      });
    } catch (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        dispatch({
          type: START_SYNC_ERROR,
          payload: { startSyncError: error.response.data },
        });
      }
    }
  };

export const getSyncs =
  (page = 1, hideInconsequential = true) =>
  async (dispatch, getState) => {
    dispatch({
      type: GET_SYNCS_START,
    });

    const {
      syncs: { syncsPath },
    } = getState();

    try {
      const response = await axios({
        method: 'get',
        url: syncsPath,
        params: { page, hide_inconsequential: hideInconsequential },
        validateStatus: (status) => status === 200,
      });

      dispatch({
        type: GET_SYNCS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        dispatch({
          type: GET_SYNCS_ERROR,
          payload: { getSyncsError: error.response.data },
        });
      }
    }
  };
