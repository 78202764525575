const actionRoot = 'mc-squarespace-commerce';

/// // session constants /////
export const SET_DASHBOARD_ENABLEMENT_PATH = `${actionRoot}/SET_DASHBOARD_ENABLEMENT_PATH`;
export const SET_SESSION_PATH = `${actionRoot}/SET_SESSION_PATH`;
export const SET_AUTHENTICITY_TOKEN = `${actionRoot}/SET_AUTHENTICITY_TOKEN`;
export const SET_SQUARESPACE_WEBSITE_SYNC_NONPHYSICAL_PRODUCTS_SETTING_PATH = `${actionRoot}/SET_SQUARESPACE_WEBSITE_SYNC_NONPHYSICAL_PRODUCTS_SETTING_PATH`;
export const SET_REMOTE_SERVICES_CONNECTION_PATH = `${actionRoot}/SET_REMOTE_SERVICES_CONNECTION_PATH}`;

// * get session
export const GET_SESSION_START = `${actionRoot}/GET_SESSION_START`;
export const GET_SESSION_SUCCESS = `${actionRoot}/GET_SESSION_SUCCESS`;
export const GET_SESSION_ERROR = `${actionRoot}/GET_SESSION_ERROR`;

// * squarespace website show dashboard
export const POST_ENABLE_DASHBOARD_START = `${actionRoot}/POST_ENABLE_DASHBOARD_START`;
export const POST_ENABLE_DASHBOARD_SUCCESS = `${actionRoot}/POST_ENABLE_DASHBOARD_SUCCESS`;
export const POST_ENABLE_DASHBOARD_ERROR = `${actionRoot}/POST_ENABLE_DASHBOARD_ERROR`;

// * set sync non physical products
export const SET_SYNC_NON_PHYSICAL_PRODUCTS_START = `${actionRoot}/SET_SYNC_NON_PHYSICAL_PRODUCTS_START`;
export const SET_SYNC_NON_PHYSICAL_PRODUCTS_SUCCESS = `${actionRoot}/SET_SYNC_NON_PHYSICAL_PRODUCTS_SUCCESS`;
export const SET_SYNC_NON_PHYSICAL_PRODUCTS_ERROR = `${actionRoot}/SET_SYNC_NON_PHYSICAL_PRODUCTS_ERROR`;

/// // list constants /////
export const SET_LISTS_PATH = `${actionRoot}/SET_LISTS_PATH`;
export const SET_MAILCHIMP_USER_LIST_PATH = `${actionRoot}/SET_MAILCHIMP_USER_LIST_PATH`;
export const SET_SELECTED_LIST_ID = `${actionRoot}/SET_SELECTED_LIST_ID`;

// * get lists
export const GET_LISTS_START = `${actionRoot}/GET_LISTS_START`;
export const GET_LISTS_SUCCESS = `${actionRoot}/GET_LISTS_SUCCESS`;
export const GET_LISTS_ERROR = `${actionRoot}/GET_LISTS_ERROR`;

// * create list
export const CREATE_LIST_START = `${actionRoot}/CREATE_LIST_START`;
export const CREATE_LIST_SUCCESS = `${actionRoot}/CREATE_LIST_SUCCESS`;
export const CREATE_LIST_ERROR = `${actionRoot}/CREATE_LIST_ERROR`;
export const RESET_CREATE_LIST_ERROR = `${actionRoot}/RESET_CREATE_LIST_ERROR`;

// * set selected list
export const SAVE_SELECTED_LIST_START = `${actionRoot}/SAVE_SELECTED_LIST_START`;
export const SAVE_SELECTED_LIST_SUCCESS = `${actionRoot}/SAVE_SELECTED_LIST_SUCCESS`;
export const SAVE_SELECTED_LIST_ERROR = `${actionRoot}/SAVE_SELECTED_LIST_ERROR`;

// * delete remote services connection
export const DELETE_REMOTE_SERVICES_CONNECTION_START = `${actionRoot}/DELETE_REMOTE_SERVICES_CONNECTION_START`;
export const DELETE_REMOTE_SERVICES_CONNECTION_SUCCESS = `${actionRoot}/DELETE_REMOTE_SERVICES_CONNECTION_SUCCESS`;
export const DELETE_REMOTE_SERVICES_CONNECTION_ERROR = `${actionRoot}/DELETE_REMOTE_SERVICES_CONNECTION_ERROR`;

/// // syncs constants /////
export const SET_SYNCS_PATH = `${actionRoot}/SET_SYNCS_PATH`;
export const SET_SYNC_PATH = `${actionRoot}/SET_SYNC_PATH`;
export const SET_SQUARESPACE_WEBHOOK_SUBSCRIPTIONS_PATH = `${actionRoot}/SET_SQUARESPACE_WEBHOOK_SUBSCRIPTIONS_PATH`;

// * start sync
export const START_SYNC_START = `${actionRoot}/START_SYNC_START`;
export const START_SYNC_SUCCESS = `${actionRoot}/START_SYNC_SUCCESS`;
export const START_SYNC_ERROR = `${actionRoot}/START_SYNC_ERROR`;

// * get sync
export const GET_SYNC_START = `${actionRoot}/GET_SYNC_START`;
export const GET_SYNC_SUCCESS = `${actionRoot}/GET_SYNC_SUCCESS`;
export const GET_SYNC_ERROR = `${actionRoot}/GET_SYNC_ERROR`;

// * sync polling interval
export const SET_SYNC_POLLING_INTERVAL_ID = `${actionRoot}/SET_SYNC_POLLING_INTERVAL_ID`;
export const SET_SYNC_POLLING_FREQUENCY = `${actionRoot}/SET_SYNC_POLLING_FREQUENCY`;

// * get syncs
export const GET_SYNCS_START = `${actionRoot}/GET_SYNCS_START`;
export const GET_SYNCS_SUCCESS = `${actionRoot}/GET_SYNCS_SUCCESS`;
export const GET_SYNCS_ERROR = `${actionRoot}/GET_SYNCS_ERROR`;

/// // Account creation // ///
export const SET_MAILCHIMP_USERNAME_AVAILABILITY_PATH = `${actionRoot}/SET_MAILCHIMP_USERNAME_AVAILABILITY_PATH`;
export const SET_MAILCHIMP_USERNAME_SUGGESTION_PATH = `${actionRoot}/SET_MAILCHIMP_USERNAME_SUGGESTION_PATH`;
export const SET_MAILCHIMP_SIGNUP_OPTIONS_PATH = `${actionRoot}/SET_MAILCHIMP_SIGNUP_OPTIONS_PATH`;
export const SET_MAILCHIMP_SIGNUPS_PATH = `${actionRoot}/SET_MAILCHIMP_SIGNUPS_PATH`;

export const GET_MAILCHIMP_USERNAME_AVAILABILITY_START = `${actionRoot}/GET_MAILCHIMP_USERNAME_AVAILABILITY_START`;
export const GET_MAILCHIMP_USERNAME_AVAILABILITY_SUCCESS = `${actionRoot}/GET_MAILCHIMP_USERNAME_AVAILABILITY_SUCCESS`;
export const GET_MAILCHIMP_USERNAME_AVAILABILITY_ERROR = `${actionRoot}/GET_MAILCHIMP_USERNAME_AVAILABILITY_ERROR`;

export const GET_MAILCHIMP_USERNAME_SUGGESTION_START = `${actionRoot}/GET_MAILCHIMP_USERNAME_SUGGESTION_START`;
export const GET_MAILCHIMP_USERNAME_SUGGESTION_SUCCESS = `${actionRoot}/GET_MAILCHIMP_USERNAME_SUGGESTION_SUCCESS`;
export const GET_MAILCHIMP_USERNAME_SUGGESTION_ERROR = `${actionRoot}/GET_MAILCHIMP_USERNAME_SUGGESTION_ERROR`;

export const GET_MAILCHIMP_SIGNUP_OPTIONS_START = `${actionRoot}/GET_MAILCHIMP_SIGNUP_OPTIONS_START`;
export const GET_MAILCHIMP_SIGNUP_OPTIONS_SUCCESS = `${actionRoot}/GET_MAILCHIMP_SIGNUP_OPTIONS_SUCCESS`;
export const GET_MAILCHIMP_SIGNUP_OPTIONS_ERROR = `${actionRoot}/GET_MAILCHIMP_SIGNUP_OPTIONS_ERROR`;

export const POST_MAILCHIMP_SIGNUP_START = `${actionRoot}/POST_MAILCHIMP_SIGNUP_START`;
export const POST_MAILCHIMP_SIGNUP_SUCCESS = `${actionRoot}/POST_MAILCHIMP_SIGNUP_SUCCESS`;
export const POST_MAILCHIMP_SIGNUP_ERROR = `${actionRoot}/POST_MAILCHIMP_SIGNUP_ERROR`;
