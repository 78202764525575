export default {
  container: (provided) => ({
    ...provided,
    marginTop: "var(--spacing-1)",
    lineHeight: "var(--line-height-text)",
    "& span": {
      border: "1px solid red",
    },
  }),
  control: (provided, state) => {
    const focusedStyles = {};
    if (state.isFocused) {
      focusedStyles.outline = "2px solid var(--brand-kale)";
      focusedStyles.outlineOffset = "1px";
    }

    return {
      ...provided,
      ...focusedStyles,
      margin: "0",
      borderRadius: "0",
      boxShadow: "none",
      border: "1px solid var(--brand-peppercorn-65)",
      ":hover": {
        border: "1px solid var(--brand-peppercorn-65)",
        cursor: "pointer",
      },
    };
  },
  dropdownIndicator: (provided) => ({
    ...provided,
    marginTop: 0,
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    marginTop: 0,
    svg: {
      color: "var(--brand-peppercorn)",
    },
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "var(--spacing-2) 0 var(--spacing-2) var(--spacing-2)",
  }),
  input: () => ({ margin: "0" }),
  menuList: (provided) => ({
    ...provided,
    "& div": { marginTop: "auto" },
  }),
};
