import React from 'react';
import PropTypes from 'prop-types';

const EyeShowIcon = ({
  alt = 'Show',
  className = '',
  size = 24,
  onClick = () => {},
}) => (
  <svg
    className={`wink-icon ${className}`}
    width={size}
    height={size}
    onClick={onClick}
    viewBox="0 0 24 24"
    aria-labelledby="EyeShowIcon"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="EyeShowIcon">{alt}</title>
    <g clipPath="url(#clip0)">
      <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 19c6.494 0 10.254-4.338 11.8-6.4a1 1 0 000-1.2C22.254 9.338 18.494 5 12 5 5.506 5 1.746 9.338.2 11.4a1 1 0 000 1.2C1.746 14.662 5.506 19 12 19zm6.238-10.18c1.549.987 2.7 2.2 3.494 3.18-.794.98-1.945 2.193-3.494 3.18A6.97 6.97 0 0019 12a6.97 6.97 0 00-.762-3.18zM12 7a5 5 0 100 10 5 5 0 000-10zm-9.732 5c.794.98 1.945 2.193 3.494 3.18A6.97 6.97 0 015 12a6.97 6.97 0 01.762-3.18c-1.549.987-2.7 2.2-3.494 3.18z"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

EyeShowIcon.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
  onClick: PropTypes.func,
};

export default EyeShowIcon;
