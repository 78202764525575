import { produce } from 'immer';
import {
  SET_SESSION_PATH,
  GET_SESSION_START,
  GET_SESSION_SUCCESS,
  GET_SESSION_ERROR,
  SET_AUTHENTICITY_TOKEN,
  SAVE_SELECTED_LIST_SUCCESS,
  SET_REMOTE_SERVICES_CONNECTION_PATH,
  DELETE_REMOTE_SERVICES_CONNECTION_START,
  DELETE_REMOTE_SERVICES_CONNECTION_SUCCESS,
  DELETE_REMOTE_SERVICES_CONNECTION_ERROR,
  POST_MAILCHIMP_SIGNUP_SUCCESS,
  SET_SQUARESPACE_WEBSITE_SYNC_NONPHYSICAL_PRODUCTS_SETTING_PATH,
  SET_SYNC_NON_PHYSICAL_PRODUCTS_START,
  SET_SYNC_NON_PHYSICAL_PRODUCTS_SUCCESS,
  SET_SYNC_NON_PHYSICAL_PRODUCTS_ERROR,
  SET_DASHBOARD_ENABLEMENT_PATH,
  POST_ENABLE_DASHBOARD_START,
  POST_ENABLE_DASHBOARD_SUCCESS,
  POST_ENABLE_DASHBOARD_ERROR,
} from 'constants/action-types';

export const INITIAL_STATE = {
  dashboardEnablementPath: null,
  sessionPath: null,
  squarespaceWebsiteSyncNonphysicalProductSettingPath: null,
  remoteServicesConnectionPath: null,
  loadingSession: false,
  loadSessionError: null,
  squarespaceWebsite: null,
  mailchimpUser: null,
  authenticityToken: null,
  postingEnableDashboard: false,
  postEnableDashboardError: null,
  puttingSyncNonphysicalProductSetting: false,
  putSyncNonphysicalProductSettingError: null,
  deletingRemoteServicesConnection: false,
  deleteRemoteServicesConnectionError: null,
};

function sessionReducer(state = INITIAL_STATE, { type, payload } = {}) {
  switch (type) {
    case SET_DASHBOARD_ENABLEMENT_PATH:
      return produce(state, (draftState) => {
        draftState.dashboardEnablementPath = payload.dashboardEnablementPath;
      });
    case SET_SESSION_PATH:
      return produce(state, (draftState) => {
        draftState.sessionPath = payload.sessionPath;
      });
    case SET_SQUARESPACE_WEBSITE_SYNC_NONPHYSICAL_PRODUCTS_SETTING_PATH:
      return produce(state, (draftState) => {
        draftState.squarespaceWebsiteSyncNonphysicalProductSettingPath =
          payload.squarespaceWebsiteSyncNonphysicalProductSettingPath;
      });
    case SET_REMOTE_SERVICES_CONNECTION_PATH:
      return produce(state, (draftState) => {
        draftState.remoteServicesConnectionPath =
          payload.remoteServicesConnectionPath;
      });
    case SET_AUTHENTICITY_TOKEN:
      return produce(state, (draftState) => {
        draftState.authenticityToken = payload.authenticityToken;
      });
    case GET_SESSION_START:
      return produce(state, (draftState) => {
        draftState.loadingSession = true;
      });
    case GET_SESSION_SUCCESS:
      return produce(state, (draftState) => {
        const { mailchimpUser, mailchimpList, ...squarespaceUser } = payload;
        draftState.squarespaceWebsite = squarespaceUser;
        draftState.mailchimpUser = mailchimpUser && {
          ...mailchimpUser,
          ...mailchimpList,
        };
        draftState.loadSessionError = null;
        draftState.loadingSession = false;
      });
    case POST_MAILCHIMP_SIGNUP_SUCCESS:
      return produce(state, (draftState) => {
        draftState.mailchimpUser = payload;
      });
    case GET_SESSION_ERROR:
      return produce(state, (draftState) => {
        draftState.squarespaceWebsite = null;
        draftState.mailchimpUser = null;
        draftState.loadSessionError = payload.loadSessionError;
        draftState.loadingSession = false;
      });
    case SAVE_SELECTED_LIST_SUCCESS:
      return produce(state, (draftState) => {
        draftState.mailchimpUser.remoteListId = payload.remoteListId;
      });
    case POST_ENABLE_DASHBOARD_START:
      return produce(state, (draftState) => {
        draftState.postingEnableDashboard = true;
        draftState.postEnableDashboardError = null;
      });
    case POST_ENABLE_DASHBOARD_SUCCESS:
      return produce(state, (draftState) => {
        draftState.postingEnableDashboard = false;
        draftState.postEnableDashboardError = null;
        draftState.squarespaceWebsite.showDashboard = true;
      });
    case POST_ENABLE_DASHBOARD_ERROR:
      return produce(state, (draftState) => {
        draftState.postingEnableDashboard = false;
        draftState.postEnableDashboardError = payload.postEnableDashboardError;
      });
    case SET_SYNC_NON_PHYSICAL_PRODUCTS_START:
      return produce(state, (draftState) => {
        draftState.puttingSyncNonphysicalProductSetting = true;
        draftState.putSyncNonphysicalProductSettingError = null;
      });
    case SET_SYNC_NON_PHYSICAL_PRODUCTS_SUCCESS:
      return produce(state, (draftState) => {
        draftState.puttingSyncNonphysicalProductSetting = false;
        draftState.putSyncNonphysicalProductSettingError = null;
        draftState.squarespaceWebsite.syncNonPhysicalProducts =
          payload.syncNonphysicalProducts;
      });
    case SET_SYNC_NON_PHYSICAL_PRODUCTS_ERROR:
      return produce(state, (draftState) => {
        draftState.puttingSyncNonphysicalProductSetting = false;
        draftState.putSyncNonphysicalProductSettingError =
          payload.setSyncNonphysicalProductsError;
      });
    case DELETE_REMOTE_SERVICES_CONNECTION_START:
      return produce(state, (draftState) => {
        draftState.deletingRemoteServicesConnection = true;
        draftState.deleteRemoteServicesConnectionError = null;
      });
    case DELETE_REMOTE_SERVICES_CONNECTION_SUCCESS:
      return produce(state, (draftState) => {
        draftState.deletingRemoteServicesConnection = false;
        draftState.deleteRemoteServicesConnectionError = null;
      });
    case DELETE_REMOTE_SERVICES_CONNECTION_ERROR:
      return produce(state, (draftState) => {
        draftState.deletingRemoteServicesConnection = false;
        draftState.deleteRemoteServicesConnectionError =
          payload.deleteRemoteServicesConnectionError;
      });
    default:
      return state;
  }
}

export default sessionReducer;
