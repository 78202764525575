import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { simpleSelectorFor } from '../selectors/session-selectors';
import {
  MAILCHIMP_USER_PROP_TYPES,
  SQUARESPACE_WEBSITE_PROP_TYPES,
} from '../constants/proptypes';
import UserIdsDisplay from '../components/user-ids-display';

const UserIdsDisplayContainer = ({
  squarespaceWebsite = null,
  mailchimpUser = null,
}) => {
  if (!squarespaceWebsite) {
    return null;
  }

  return (
    <UserIdsDisplay
      squarespaceWebsite={squarespaceWebsite}
      mailchimpUser={mailchimpUser}
    />
  );
};

UserIdsDisplayContainer.propTypes = {
  squarespaceWebsite: SQUARESPACE_WEBSITE_PROP_TYPES,
  mailchimpUser: MAILCHIMP_USER_PROP_TYPES,
};

const mapDispatchToProps = {};

const mapStateToProps = createStructuredSelector({
  squarespaceWebsite: simpleSelectorFor('squarespaceWebsite'),
  mailchimpUser: simpleSelectorFor('mailchimpUser'),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserIdsDisplayContainer);
