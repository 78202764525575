import axios from "axios";
import {
  GET_MAILCHIMP_SIGNUP_OPTIONS_ERROR,
  GET_MAILCHIMP_SIGNUP_OPTIONS_START,
  GET_MAILCHIMP_SIGNUP_OPTIONS_SUCCESS,
  GET_MAILCHIMP_USERNAME_AVAILABILITY_ERROR,
  GET_MAILCHIMP_USERNAME_AVAILABILITY_START,
  GET_MAILCHIMP_USERNAME_AVAILABILITY_SUCCESS,
  GET_MAILCHIMP_USERNAME_SUGGESTION_ERROR,
  GET_MAILCHIMP_USERNAME_SUGGESTION_START,
  GET_MAILCHIMP_USERNAME_SUGGESTION_SUCCESS,
  POST_MAILCHIMP_SIGNUP_ERROR,
  POST_MAILCHIMP_SIGNUP_START,
  POST_MAILCHIMP_SIGNUP_SUCCESS,
  SET_MAILCHIMP_SIGNUP_OPTIONS_PATH,
  SET_MAILCHIMP_SIGNUPS_PATH,
  SET_MAILCHIMP_USERNAME_AVAILABILITY_PATH,
  SET_MAILCHIMP_USERNAME_SUGGESTION_PATH,
} from "constants/action-types";

export const setMailchimpUsernameAvailabilityPath = (
  mailchimpUsernameAvailabilityPath
) => ({
  type: SET_MAILCHIMP_USERNAME_AVAILABILITY_PATH,
  payload: { mailchimpUsernameAvailabilityPath },
});

export const setMailchimpUsernameSuggestionPath = (
  mailchimpUsernameSuggestionPath
) => ({
  type: SET_MAILCHIMP_USERNAME_SUGGESTION_PATH,
  payload: { mailchimpUsernameSuggestionPath },
});

export const setMailchimpSignupOptionsPath = (mailchimpSignupOptionsPath) => ({
  type: SET_MAILCHIMP_SIGNUP_OPTIONS_PATH,
  payload: { mailchimpSignupOptionsPath },
});

export const setMailchimpSignupsPath = (mailchimpSignupsPath) => ({
  type: SET_MAILCHIMP_SIGNUPS_PATH,
  payload: { mailchimpSignupsPath },
});

export const getMailchimpUsernameAvailability = (username) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: GET_MAILCHIMP_USERNAME_AVAILABILITY_START,
  });

  const { mailchimpUsernameAvailabilityPath } = getState().accountCreation;

  try {
    const response = await axios({
      method: "get",
      url: mailchimpUsernameAvailabilityPath.replace(":username", username),
      validateStatus: (status) => status === 200,
    });

    dispatch({
      type: GET_MAILCHIMP_USERNAME_AVAILABILITY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_MAILCHIMP_USERNAME_AVAILABILITY_ERROR,
      payload: { getMailchimpUsernameAvailabilityError: error.response.data },
    });
  }
};

export const getMailchimpUsernameSuggestion = (username) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: GET_MAILCHIMP_USERNAME_SUGGESTION_START,
  });

  const { mailchimpUsernameSuggestionPath } = getState().accountCreation;

  try {
    const response = await axios({
      method: "get",
      url: mailchimpUsernameSuggestionPath.replace(":username", username),
      validateStatus: (status) => status === 200,
    });

    dispatch({
      type: GET_MAILCHIMP_USERNAME_SUGGESTION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_MAILCHIMP_USERNAME_SUGGESTION_ERROR,
      payload: { getMailchimpUsernameSuggestionError: error.response.data },
    });
  }
};

export const getMailchimpSignupOptions = () => async (dispatch, getState) => {
  dispatch({
    type: GET_MAILCHIMP_SIGNUP_OPTIONS_START,
  });

  const { mailchimpSignupOptionsPath } = getState().accountCreation;

  try {
    const response = await axios({
      method: "get",
      url: mailchimpSignupOptionsPath,
      validateStatus: (status) => status === 200,
    });

    dispatch({
      type: GET_MAILCHIMP_SIGNUP_OPTIONS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_MAILCHIMP_SIGNUP_OPTIONS_ERROR,
      payload: { getMailchimpSignupOptionsError: error.response.data },
    });
  }
};

export const signup = (newAccountDetails) => async (dispatch, getState) => {
  dispatch({
    type: POST_MAILCHIMP_SIGNUP_START,
  });

  const { mailchimpSignupsPath } = getState().accountCreation;
  const { authenticityToken } = getState().session;

  try {
    const response = await axios({
      method: "post",
      url: mailchimpSignupsPath,
      data: {
        ...newAccountDetails,
        first_name: newAccountDetails.firstName,
        last_name: newAccountDetails.lastName,
        organization_name: newAccountDetails.organizationName,
        address_line1: newAccountDetails.addressLine1,
        address_line2: newAccountDetails.addressLine2,
      },
      headers: {
        "X-CSRF-Token": authenticityToken,
      },
      validateStatus: (status) => status === 200,
    });

    await dispatch({
      type: POST_MAILCHIMP_SIGNUP_SUCCESS,
      payload: response.data,
    });

    return true;
  } catch (error) {
    await dispatch({
      type: POST_MAILCHIMP_SIGNUP_ERROR,
      payload: { postMailchimpSignupError: error.response.data },
    });

    return false;
  }
};
