import { produce } from 'immer';
import {
  SET_LISTS_PATH,
  GET_LISTS_START,
  GET_LISTS_SUCCESS,
  GET_LISTS_ERROR,
  SET_SELECTED_LIST_ID,
  CREATE_LIST_START,
  CREATE_LIST_SUCCESS,
  CREATE_LIST_ERROR,
  SET_MAILCHIMP_USER_LIST_PATH,
  SAVE_SELECTED_LIST_START,
  SAVE_SELECTED_LIST_SUCCESS,
  SAVE_SELECTED_LIST_ERROR,
  RESET_CREATE_LIST_ERROR,
} from 'constants/action-types';

export const INITIAL_STATE = {
  mailchimpListsPath: null,
  loadingLists: false,
  loadListsError: null,
  lists: [],
  maxListInstances: null,
  canCreateList: false,
  creatingNewList: false,
  selectedListId: null,
  createListError: null,
  createdNewList: false,
  mailchimpUserListPath: null,
  savingSelectedList: false,
  savedSelectedList: false,
  saveSelectedListError: null,
};

function listsReducer(state = INITIAL_STATE, { type, payload } = {}) {
  switch (type) {
    case SET_LISTS_PATH:
      return produce(state, (draftState) => {
        draftState.mailchimpListsPath = payload.mailchimpListsPath;
      });
    case SET_MAILCHIMP_USER_LIST_PATH:
      return produce(state, (draftState) => {
        draftState.mailchimpUserListPath = payload.mailchimpUserListPath;
      });
    case GET_LISTS_START:
      return produce(state, (draftState) => {
        draftState.loadingLists = true;
      });
    case GET_LISTS_SUCCESS:
      return produce(state, (draftState) => {
        draftState.lists = payload.lists;
        draftState.maxListInstances = payload.constraints.maxInstances;
        draftState.canCreateList = payload.constraints.mayCreate;
        draftState.loadListsError = null;
        draftState.loadingLists = false;
      });
    case GET_LISTS_ERROR:
      return produce(state, (draftState) => {
        draftState.lists = [];
        draftState.maxListInstances = null;
        draftState.canCreateList = false;
        draftState.loadListsError = payload.loadListsError;
        draftState.loadingLists = false;
      });
    case CREATE_LIST_START:
      return produce(state, (draftState) => {
        draftState.creatingNewList = true;
        draftState.createListError = null;
        draftState.createdNewList = false;
      });
    case SET_SELECTED_LIST_ID:
      return produce(state, (draftState) => {
        draftState.selectedListId = payload.selectedListId;
        draftState.createListError = null;
        draftState.createdNewList = false;
      });
    case CREATE_LIST_SUCCESS:
      return produce(state, (draftState) => {
        draftState.creatingNewList = false;
        draftState.createListError = null;
        draftState.selectedListId = payload.id;
        draftState.createdNewList = true;
      });
    case CREATE_LIST_ERROR:
      return produce(state, (draftState) => {
        draftState.creatingNewList = false;
        draftState.createListError = payload.createListError;
        draftState.createdNewList = false;
      });
    case RESET_CREATE_LIST_ERROR:
      return produce(state, (draftState) => {
        draftState.creatingNewList = false;
        draftState.createListError = null;
        draftState.createdNewList = false;
      });
    case SAVE_SELECTED_LIST_START:
      return produce(state, (draftState) => {
        draftState.savingSelectedList = true;
        draftState.saveSelectedListError = null;
        draftState.savedSelectedList = false;
      });
    case SAVE_SELECTED_LIST_SUCCESS:
      return produce(state, (draftState) => {
        draftState.savingSelectedList = false;
        draftState.saveSelectedListError = null;
        draftState.savedSelectedList = true;
      });
    case SAVE_SELECTED_LIST_ERROR:
      return produce(state, (draftState) => {
        draftState.savingSelectedList = false;
        draftState.saveSelectedListError = payload.saveSelectedListError;
        draftState.savedSelectedList = false;
      });
    default:
      return state;
  }
}

export default listsReducer;
