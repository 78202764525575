import React from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

const MailchimpAccountCreationSuccess = (props) => {
  const { push } = props;

  return (
    <div className="mailchimp-account-creation-container">
      <div className="success-container">
        <div className="text-container">
          <h2>All set up!</h2>
          <h3>We’re taking you back to continue your integration setup.</h3>
          <p>
            Not redirected after a few seconds?{' '}
            <button
              type="button"
              className="link"
              onClick={() => {
                push('/settings');
              }}
            >
              Continue Setup
            </button>
            .
          </p>
        </div>
      </div>
      <meta httpEquiv="refresh" content="2; url=#/settings" />
    </div>
  );
};

MailchimpAccountCreationSuccess.propTypes = {
  push: PropTypes.func.isRequired,
};

const mapDispatchToProps = { push };

const mapStateToProps = createStructuredSelector({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MailchimpAccountCreationSuccess);
