import React from 'react';
import { SYNC_PROP_TYPES } from 'constants/proptypes';
import ActivityIndicator from './activity-indicator';
import SyncObjectTypeStatus from './sync-object-type-status';

const SyncStatus = ({ sync = null }) => (
  <>
    <div className="overall-status">
      {(sync?.status === 'in_progress' || sync?.status === 'retrying') && (
        <div className="sync-status">
          <ActivityIndicator />
        </div>
      )}
    </div>
    <div className="sync-status">
      <SyncObjectTypeStatus
        objectType="product"
        status={sync?.status}
        count={sync?.products || 0}
      />
      <SyncObjectTypeStatus
        objectType="customer"
        status={sync?.status}
        count={sync?.profiles || 0}
      />
      <SyncObjectTypeStatus
        objectType="order"
        status={sync?.status}
        count={sync?.orders || 0}
      />
    </div>
  </>
);

SyncStatus.propTypes = {
  sync: SYNC_PROP_TYPES,
};

export default SyncStatus;
