import React from 'react';

const Support = () => (
  <div className="faqs">
    <h1>FAQs</h1>
    <dl>
      <dt className="wink-h2">
        What Squarespace Plans are required for this extension?
      </dt>
      <dd>
        You will need a Squarespace Commerce or Business plan to utilize the
        features of this extension.
      </dd>
      <dt className="wink-h2">
        I don’t see an option to map my Mailchimp Audience fields, are those
        synced automatically?
      </dt>
      <dd>
        Yes! We auto-map these fields from Squarespace to Mailchimp: First Name,
        Last Name, Address and Phone Number.
      </dd>
      <dt className="wink-h2">
        How do I access Squarespace products in Mailchimp?
      </dt>
      <dd>
        You can add a{' '}
        <a
          href="https://mailchimp.com/help/use-product-content-blocks/"
          target="_blank"
          rel="noreferrer"
        >
          Product content block
        </a>{' '}
        to your email to quickly promote best-selling products or special
        offers. Drag and drop the block into your layout, and choose the
        products you want to share. Then, check out revenue results in your
        report.
      </dd>
      <dt className="wink-h2">How is Subscription Status synced?</dt>
      <dd>
        All customers will be synced over with an opt-in status (Subscribed or
        Unsubscribed) based on the subscription value that comes from
        Squarespace. Squarespace offers a{' '}
        <a
          href="https://support.squarespace.com/hc/en-us/articles/360002109868-Getting-permission-to-send-marketing-emails"
          target="_blank"
          rel="noreferrer"
        >
          subscription option
        </a>{' '}
        that can be added to your checkout flow.
      </dd>
      <dt className="wink-h2">Is Double Opt-in Supported?</dt>
      <dd>
        Yes! You can choose to{' '}
        <a
          href="https://mailchimp.com/help/about-double-opt-in/"
          target="_blank"
          rel="noreferrer"
        >
          enable double opt-in
        </a>{' '}
        when you create an Audience in Mailchimp.
      </dd>
      <dt className="wink-h2">What product types are supported?</dt>
      <dd>
        <p>
          Squarespace has{' '}
          <a
            href="https://support.squarespace.com/hc/en-us/articles/205811338-Adding-products-to-your-store"
            target="_blank"
            rel="noreferrer"
          >
            4 product types
          </a>
          : Physical Products, Digital Products, Service Products, and Gift
          Cards. We do not sync anything related to donations.
        </p>

        <p>Physical products sync as expected.</p>

        <p>
          Digital products sync differently - product variants, URL, name,
          created date and is_visible are not able to be synced to Mailchimp.
        </p>
      </dd>
      <dt className="wink-h2">
        Am I able to turn off transactional emails at Squarespace?
      </dt>
      <dd>
        Currently there is not a way to turn off transactional emails that are
        sent from Squarespace. These{' '}
        <a
          href="https://support.squarespace.com/hc/en-us/articles/205811258-Order-emails"
          target="_blank"
          rel="noreferrer"
        >
          order emails
        </a>{' '}
        include: Order Confirmed, Order Fulfilled, and Order Refunded.
      </dd>
      <dt className="wink-h2">Are Abandoned Carts supported?</dt>
      <dd>
        No. Squarespace does not surface Abandoned Carts through the API, so
        this feature is not supported at this time.
      </dd>
      <dt className="wink-h2">Are Promo Codes supported?</dt>
      <dd>
        Promo codes are not supported at this time. If you’re interested in this
        functionality, please let us know by submitting a{' '}
        <a
          href="https://admin.mailchimp.com/support/ecommerce-support"
          target="_blank"
          rel="noreferrer"
        >
          feature request
        </a>{' '}
        with our Support team.
      </dd>
      <dt className="wink-h2">How far back does my data sync to Mailchimp?</dt>
      <dd>All historical information will be synced.</dd>
    </dl>
  </div>
);

Support.propTypes = {};

export default Support;
