import { produce } from 'immer';
import {
  GET_SYNC_ERROR,
  GET_SYNC_START,
  GET_SYNC_SUCCESS,
  SET_SYNC_PATH,
  SET_SYNCS_PATH,
  SET_SQUARESPACE_WEBHOOK_SUBSCRIPTIONS_PATH,
  START_SYNC_ERROR,
  START_SYNC_START,
  START_SYNC_SUCCESS,
  SET_SYNC_POLLING_INTERVAL_ID,
  GET_SYNCS_START,
  GET_SYNCS_SUCCESS,
  GET_SYNCS_ERROR,
  SET_SYNC_POLLING_FREQUENCY,
} from 'constants/action-types';

export const INITIAL_STATE = {
  syncsPath: null,
  syncPath: null,
  squarespaceWebhookSubscriptionsPath: null,
  startingSync: false,
  startSyncError: null,
  sync: null,
  gettingSync: false,
  getSyncError: null,
  syncPollingIntervalId: null,
  syncPollingFrequency: 1500,
  gettingSyncs: false,
  getSyncsError: null,
  syncs: null,
  syncsPagination: null,
};

function syncsReducer(state = INITIAL_STATE, { type, payload } = {}) {
  switch (type) {
    case SET_SYNCS_PATH:
      return produce(state, (draftState) => {
        draftState.syncsPath = payload.syncsPath;
      });
    case SET_SYNC_PATH:
      return produce(state, (draftState) => {
        draftState.syncPath = payload.syncPath;
      });
    case SET_SQUARESPACE_WEBHOOK_SUBSCRIPTIONS_PATH:
      return produce(state, (draftState) => {
        draftState.squarespaceWebhookSubscriptionsPath =
          payload.squarespaceWebhookSubscriptionsPath;
      });
    case START_SYNC_START:
      return produce(state, (draftState) => {
        draftState.startingSync = true;
        draftState.startSyncError = null;
      });
    case START_SYNC_SUCCESS:
      return produce(state, (draftState) => {
        draftState.startingSync = false;
        draftState.startSyncError = null;
        draftState.sync = payload;
      });
    case START_SYNC_ERROR:
      return produce(state, (draftState) => {
        draftState.startingSync = false;
        draftState.startSyncError = payload.startSyncError;
        draftState.sync = null;
      });
    case GET_SYNC_START:
      return produce(state, (draftState) => {
        draftState.gettingSync = true;
        draftState.getSyncError = null;
      });
    case GET_SYNC_SUCCESS:
      return produce(state, (draftState) => {
        draftState.gettingSync = false;
        draftState.getSyncError = null;
        draftState.sync = payload;
      });
    case GET_SYNC_ERROR:
      return produce(state, (draftState) => {
        draftState.gettingSync = false;
        draftState.getSyncError = payload.getSyncError;
        draftState.sync = null;
      });
    case SET_SYNC_POLLING_INTERVAL_ID:
      return produce(state, (draftState) => {
        draftState.syncPollingIntervalId = payload.syncPollingIntervalId;
      });
    case GET_SYNCS_START:
      return produce(state, (draftState) => {
        draftState.gettingSyncs = true;
        draftState.getSyncsError = null;
      });
    case GET_SYNCS_SUCCESS:
      return produce(state, (draftState) => {
        draftState.gettingSyncs = false;
        draftState.getSyncsError = null;
        draftState.syncs = payload.data;
        draftState.syncsPagination = payload.pagination;
      });
    case GET_SYNCS_ERROR:
      return produce(state, (draftState) => {
        draftState.gettingSyncs = false;
        draftState.getSyncsError = payload.getSyncsError;
        draftState.syncs = null;
        draftState.syncsPagination = null;
      });
    case SET_SYNC_POLLING_FREQUENCY:
      return produce(state, (draftState) => {
        draftState.syncPollingFrequency = payload.syncPollingFrequency;
      });
    default:
      return state;
  }
}

export default syncsReducer;
