import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  selectSyncNonPhysicalProducts,
  simpleSelectorFor,
} from 'selectors/session-selectors';
import Modal from 'components/modal';
import InfoCircleIcon from 'components/icons/info-circle-icon';
import { setSyncNonphysicalProducts } from 'actions/session-actions';
import Spinner from 'components/spinner';

const NonPhysicalProducts = (props) => {
  const {
    syncNonPhysicalProducts,
    setSyncNonphysicalProducts,
    puttingSyncNonphysicalProductSetting,
  } = props;

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const toggleSyncNonPhysicalProducts = () => {
    if (!syncNonPhysicalProducts) {
      setShowConfirmationDialog(true);
    } else {
      setSyncNonphysicalProducts(false);
    }
  };

  return (
    <div>
      <Modal
        modalClassName="non-physical-sync-modal"
        isOpen={showConfirmationDialog}
      >
        <h3>Not All Non-Physical Product Details Can be Synced to Mailchimp</h3>
        <p>
          Squarespace provides 3 non-physical product types:{' '}
          <a
            href="https://support.squarespace.com/hc/en-us/articles/206540787"
            target="_blank"
            rel="noreferrer"
          >
            Digital Products
          </a>
          ,{' '}
          <a
            href="https://support.squarespace.com/hc/en-us/articles/206540867"
            target="_blank"
            rel="noreferrer"
          >
            Service Products
          </a>
          , and{' '}
          <a
            href="https://support.squarespace.com/hc/en-us/articles/115012234727"
            target="_blank"
            rel="noreferrer"
          >
            Gift Cards
          </a>
          .
        </p>
        <div className="wink-feedback wink-feedback-warning">
          <InfoCircleIcon />
          <div className="wink-feedback-message">
            <h2 className="wink-text-tag">
              Not all details about non-physical products can be synced to
              Mailchimp!
            </h2>
          </div>
        </div>

        <ul>
          <li>
            Non-physical products only sync title, url, visibility, and image
            url. However, sometimes even this limited data is not provided to
            Mailchimp and cannot be synced.
          </li>
          <li>
            Non-physical products are only synced when they have been purchased.
          </li>
          <li>Donations cannot be synced.</li>
        </ul>

        <form>
          <div className="wink-form-field-container action-buttons">
            <button
              type="button"
              className="wink-button wink-button-secondary"
              onClick={() => setShowConfirmationDialog(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="wink-button wink-button-primary"
              onClick={async () => {
                setSyncNonphysicalProducts(true);
                setShowConfirmationDialog(false);
              }}
            >
              I Understand
            </button>
          </div>
        </form>
      </Modal>
      <div className="wink-form-control-container">
        <label className="wink-form-control-label" id="sync-non-physical">
          {puttingSyncNonphysicalProductSetting ? (
            <Spinner />
          ) : (
            <input
              type="checkbox"
              className="wink-form-control wink-form-control-checkbox"
              checked={syncNonPhysicalProducts}
              onChange={() => toggleSyncNonPhysicalProducts()}
            />
          )}
          Sync non-physical products
        </label>

        <div
          aria-describedby="sync-non-physical"
          className="wink-form-description"
        >
          Squarespace has three types of non-physical products: Digital
          Products, Service Products, and Gift Cards. By default, these products
          are not synced to Mailchimp. Only a subset of data about non-physical
          products can be synced.
        </div>
      </div>
    </div>
  );
};

NonPhysicalProducts.propTypes = {
  syncNonPhysicalProducts: PropTypes.bool.isRequired,
  setSyncNonphysicalProducts: PropTypes.func.isRequired,
  puttingSyncNonphysicalProductSetting: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
  setSyncNonphysicalProducts,
};

const mapStateToProps = createStructuredSelector({
  syncNonPhysicalProducts: selectSyncNonPhysicalProducts,
  puttingSyncNonphysicalProductSetting: simpleSelectorFor(
    'puttingSyncNonphysicalProductSetting'
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NonPhysicalProducts);
