import { createSelector } from 'reselect';

export const selectSlice = (state) => state.lists;

export const simpleSelectorFor = (field) =>
  createSelector(selectSlice, (state) => state[field]);

export const selectSelectedList = createSelector(selectSlice, (state) =>
  state.lists.find((list) => list.id === state.selectedListId)
);
