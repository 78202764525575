import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { loadSession } from 'actions/session-actions';
import { simpleSelectorFor } from 'selectors/session-selectors';
import Loading from 'components/loading';
import { SQUARESPACE_WEBSITE_PROP_TYPES } from 'constants/proptypes';
import SetupFlowContainer from './setup-flow-container';
import DashboardContainer from './dashboard-container';

const ApplicationContainer = ({ loadSession, squarespaceWebsite = null }) => {
  useEffect(() => {
    loadSession();
  }, []);

  if (!squarespaceWebsite) return <Loading />;

  return squarespaceWebsite?.showDashboard ? (
    <DashboardContainer />
  ) : (
    <SetupFlowContainer />
  );
};

ApplicationContainer.propTypes = {
  loadSession: PropTypes.func.isRequired,
  squarespaceWebsite: SQUARESPACE_WEBSITE_PROP_TYPES,
};

const mapDispatchToProps = { loadSession };

const mapStateToProps = createStructuredSelector({
  squarespaceWebsite: simpleSelectorFor('squarespaceWebsite'),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationContainer);
