import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { getSyncs } from 'actions/syncs-actions';
import { simpleSelectorFor } from 'selectors/syncs-selectors';
import { SYNCS_PAGINATION_TYPE, SYNCS_PROP_TYPE } from 'constants/proptypes';
import SpinnerLabel from 'components/spinner-label';
import MenuLeftIcon from 'components/icons/menu-left-icon';
import MenuRightIcon from 'components/icons/menu-right-icon';
import EyeShowIcon from 'components/icons/eye-show-icon';
import IconLabel from 'components/icon-label';
import EyeHideIcon from 'components/icons/eye-hide-icon';

const SyncLogs = ({ getSyncs, syncs = null, syncsPagination = null }) => {
  const [page, setPage] = useState(1);
  const [hideInconsequential, setHideInconsequential] = useState(true);

  useEffect(() => {
    getSyncs(page, hideInconsequential);
  }, [page, hideInconsequential]);

  return (
    <div>
      <h1>Sync Logs</h1>

      {syncs ? (
        <div>
          <p>
            New syncs are triggered as purchases are made or updated on your
            Squarespace website. They also occur on a scheduled basis. Scheduled
            syncs only sync orders, products, and customers that have not
            already been synced.
          </p>
          <table>
            <thead>
              <tr>
                <th>Started</th>
                <th>Trigger</th>
                <th>Status</th>
                <th className="above-small">Orders</th>
                <th className="above-small">Products</th>
                <th className="above-small">Customers</th>
                <th className="below-small">
                  Items<sup>*</sup>
                </th>
                <th className="above-small">Troubleshooting Info</th>
              </tr>
            </thead>
            <tbody>
              {syncs.map((sync) => (
                <tr key={sync.id}>
                  <td
                    title={new Intl.DateTimeFormat('default', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      second: 'numeric',
                    }).format(new Date(Date.parse(sync.createdAt)))}
                  >
                    {sync.createdAgo} ago
                  </td>
                  <td>
                    {sync.triggeredBy === 'sync_order'
                      ? 'Order Created or Changed'
                      : 'Scheduled'}
                  </td>
                  <td>
                    {
                      {
                        in_progress: 'In progress',
                        succeeded: 'Succeeded',
                        failed: 'Failed',
                        retrying: 'Retrying',
                      }[sync.status]
                    }
                  </td>
                  <td className="above-small">{sync.orders}</td>
                  <td className="above-small">{sync.products}</td>
                  <td className="above-small">{sync.profiles}</td>
                  <td className="below-small">
                    <div className="sync-count">Orders: {sync.orders}</div>
                    <div className="sync-count">Products: {sync.products}</div>
                    <div className="sync-count">Profiles: {sync.profiles}</div>
                  </td>
                  <td className="above-small">
                    {sync.status !== 'Succeeded' &&
                      sync.lastSyncErrorFriendlyErrorMessage}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="sync-logs-footer">
            {hideInconsequential ? (
              <button
                type="button"
                className="wink-button wink-button-tertiary"
                onClick={() => {
                  setPage(1);
                  setHideInconsequential(false);
                }}
              >
                <IconLabel icon={EyeShowIcon} label="Show All" />
              </button>
            ) : (
              <button
                type="button"
                className="wink-button wink-button-tertiary"
                onClick={() => {
                  setPage(1);
                  setHideInconsequential(true);
                }}
              >
                <IconLabel
                  icon={EyeHideIcon}
                  label="Hide Inconsequential Syncs"
                />
              </button>
            )}

            <ReactPaginate
              containerClassName="pagination"
              pageCount={syncsPagination.pages}
              pageRangeDisplayed={2}
              marginPagesDisplayed={1}
              previousLinkClassName="wink-button"
              nextLinkClassName="wink-button"
              pageLinkClassName="wink-button"
              breakLinkClassName="wink-button"
              activeLinkClassName="wink-button-primary"
              disabledClassName="disabled"
              previousLabel={<MenuLeftIcon />}
              nextLabel={<MenuRightIcon />}
              onPageChange={({ selected }) => setPage(selected + 1)}
            />
          </div>
        </div>
      ) : (
        <div>
          <SpinnerLabel label="Loading sync logs..." />
        </div>
      )}
    </div>
  );
};

SyncLogs.propTypes = {
  getSyncs: PropTypes.func.isRequired,
  syncs: SYNCS_PROP_TYPE,
  syncsPagination: SYNCS_PAGINATION_TYPE,
};

const mapDispatchToProps = {
  getSyncs,
};

const mapStateToProps = createStructuredSelector({
  syncs: simpleSelectorFor('syncs'),
  syncsPagination: simpleSelectorFor('syncsPagination'),
});

export default connect(mapStateToProps, mapDispatchToProps)(SyncLogs);
