import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router';
import {
  MAILCHIMP_USER_PROP_TYPES,
  SQUARESPACE_WEBSITE_PROP_TYPES,
} from 'constants/proptypes';
import Header from 'components/header';
import MailchimpLogin from 'components/mailchimp-login';
import SetupNavLink from 'components/setup-nav-link';
import { simpleSelectorFor } from 'selectors/session-selectors';
import MailchimpAccountCreationSuccess from 'components/mailchimp-account-creation-success';
import IntegrationSettings from './setup/integration-settings';
import InitialSync from './setup/initial-sync';
import MailchimpAccountCreation from './account-creation/mailchimp-account-creation';
import UserIdsDisplayContainer from './user-ids-display-container';

const SetupFlowContainer = ({
  mailchimpUser = null,
  squarespaceWebsite: { setupStep } = null,
}) => (
  <div className="setup-container">
    <Header>
      <div className="wink-h3 wink-nomargin">
        Install Squarespace Commerce Integration
      </div>
      <ul className="installation-flow-nav">
        <li>
          <SetupNavLink to="/authenticate">Authenticate</SetupNavLink>
        </li>
        <li>
          <SetupNavLink to="/settings" enabled={!!mailchimpUser?.authorized}>
            Settings
          </SetupNavLink>
        </li>
        <li>
          <SetupNavLink to="/sync" enabled={!!mailchimpUser?.remoteListId}>
            Sync
          </SetupNavLink>
        </li>
        <li>Complete</li>
      </ul>
    </Header>

    <main className="login-to-mailchimp">
      <div>
        <Switch>
          <Route exact path="/authenticate/mailchimp/create">
            <MailchimpAccountCreation />
          </Route>
          <Route exact path="/authenticate/mailchimp/success">
            <MailchimpAccountCreationSuccess />
          </Route>
          <Route exact path="/authenticate">
            <MailchimpLogin />
          </Route>
          {mailchimpUser?.authorized && (
            <Route exact path="/settings">
              <IntegrationSettings />
            </Route>
          )}
          {mailchimpUser?.remoteListId && (
            <Route exact path="/sync">
              <InitialSync />
            </Route>
          )}
          <Route exact path="/">
            <Redirect to={`/${setupStep}`} />
          </Route>
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </div>
      <UserIdsDisplayContainer />
    </main>
  </div>
);

SetupFlowContainer.propTypes = {
  mailchimpUser: MAILCHIMP_USER_PROP_TYPES,
  squarespaceWebsite: SQUARESPACE_WEBSITE_PROP_TYPES,
};

const mapDispatchToProps = {};

const mapStateToProps = createStructuredSelector({
  mailchimpUser: simpleSelectorFor('mailchimpUser'),
  squarespaceWebsite: simpleSelectorFor('squarespaceWebsite'),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetupFlowContainer);
