import React from 'react';
import PropTypes from 'prop-types';

const EyeHideIcon = ({
  alt = 'Hide',
  className = '',
  size = 24,
  onClick = () => {},
}) => (
  <svg
    className={`wink-icon ${className}`}
    width={size}
    height={size}
    onClick={onClick}
    viewBox="0 0 24 24"
    aria-labelledby="EyeHideIcon"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="EyeHideIcon">{alt}</title>
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.707 22.707l20-20-1.414-1.414-4.552 4.551A13.42 13.42 0 0012 5C5.506 5 1.746 9.338.2 11.4a1 1 0 000 1.2c.91 1.212 2.585 3.213 5.131 4.654l-4.038 4.039 1.414 1.414zm5.121-7.95a5 5 0 016.93-6.929l-1.465 1.464a3 3 0 00-4.001 4.001l-1.464 1.464zM2.268 12c.794.979 1.945 2.193 3.494 3.18A6.97 6.97 0 015 12a6.97 6.97 0 01.762-3.18c-1.549.986-2.7 2.2-3.494 3.18z"
      />
      <path d="M12 17c-.056 0-.113-.001-.169-.003l-1.857 1.857A13.93 13.93 0 0012 19c6.494 0 10.253-4.338 11.8-6.4a1 1 0 000-1.2c-.641-.855-1.663-2.102-3.103-3.268l-1.423 1.422A15.537 15.537 0 0121.732 12c-.794.979-1.945 2.193-3.494 3.18A6.97 6.97 0 0019 12c0-.662-.092-1.302-.263-1.908l-1.74 1.74A5 5 0 0112 17z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

EyeHideIcon.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
  onClick: PropTypes.func,
};

export default EyeHideIcon;
