import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import MailchimpList from 'containers/mailchimp-list';
import Feedback from 'components/feedback';
import { saveSelectedList } from 'actions/lists-actions';
import { startSync } from 'actions/syncs-actions';
import { disconnect } from 'actions/session-actions';
import { selectSelectedList } from 'selectors/lists-selectors';
import {
  selectMailchimpUserRemoteListId,
  simpleSelectorFor,
} from 'selectors/session-selectors';
import { MAILCHIMP_LIST_PROP_TYPES } from 'constants/proptypes';
import Modal from 'components/modal';
import SpinnerLabel from 'components/spinner-label';
import NonPhysicalProducts from '../non-physical-products';

const Settings = ({
  selectedList = null,
  mailchimpUserRemoteListId = null,
  saveSelectedList,
  startSync,
  push,
  disconnect,
  deletingRemoteServicesConnection,
}) => {
  const [
    showDisconnectConfirmationDialog,
    setShowDisconnectConfirmationDialog,
  ] = useState(false);
  const [deleteRemoteData, setDeleteRemoteData] = useState(false);
  const [showDisconnectedDialog, setShowDisconnectedDialog] = useState(false);

  return (
    <div>
      <h1>Settings</h1>

      <h2>Audience</h2>

      <p>
        Your Squarespace Commerce data and customers will be synced into the
        selected list.
      </p>

      <MailchimpList />

      <form>
        <Feedback label="Changing the Mailchimp List" status="warning">
          Changing the Mailchimp list{' '}
          <em>
            removes all existing store, product, order, and customer information
          </em>
          . A new store will be created in Mailchimp and Squarespace Commerce
          data will be re-synced to the newly selected list.
        </Feedback>

        <div className="wink-form-field-container action-buttons">
          <button
            type="button"
            className="wink-button wink-button-primary"
            onClick={async () => {
              await saveSelectedList({
                remoteListId: selectedList.id,
                name: selectedList.name,
              });
              await startSync(true);
              push('/');
            }}
            disabled={
              !selectedList?.id ||
              selectedList?.id === mailchimpUserRemoteListId
            }
          >
            Change Selected List
          </button>
        </div>
      </form>

      <h2>Sync Configuration</h2>

      <NonPhysicalProducts />

      <h2>Disconnect</h2>

      <p>
        You can disconnect and stop syncing data from Squarespace Commerce to
        Mailchimp.
      </p>

      <form>
        <fieldset>
          <div className="wink-form-control-container">
            <label className="wink-form-control-label" id="disconnect">
              <input
                type="checkbox"
                className="wink-form-control wink-form-control-checkbox"
                checked={deleteRemoteData}
                onChange={(e) => setDeleteRemoteData(e.target.checked)}
              />
              Delete Store Data at Mailchimp
            </label>
            <p aria-describedby="disconnect" className="wink-form-description">
              Deleting Store Data at Mailchimp will delete the Squarespace
              Commerce store association and all products, and orders that have
              been synced.{' '}
              <strong>
                This will <em>not</em> impact any data at Squarespace!
              </strong>
            </p>
          </div>
        </fieldset>

        <div className="wink-form-field-container action-buttons">
          <button
            type="button"
            className="wink-button wink-button-primary"
            onClick={() => setShowDisconnectConfirmationDialog(true)}
          >
            <span className="below-small">Disconnect</span>
            <span className="above-small">
              Disconnect from Squarespace Commerce and Mailchimp
            </span>
          </button>
        </div>
      </form>

      <Modal isOpen={showDisconnectConfirmationDialog}>
        <h3>Do you really want to disconnect?</h3>
        <p>
          Please confirm that you wish to disconnect from Squarespace Commerce
          and Mailchimp. This will not remove the application at Squarespace or
          Mailchimp, but it will stop syncs from running.
        </p>
        {deleteRemoteData ? (
          <p>
            You have selected to delete all synced store data from Mailchimp.
            This will delete the Squarespace Commerce store association, all
            products, customers, and orders that have been synced.{' '}
            <strong>
              This will <em>not</em> impact any data at Squarespace!
            </strong>{' '}
          </p>
        ) : (
          <p>
            You have not selected to remove synced store data from Mailchimp.
            All synced products, customers, and orders will remain.
          </p>
        )}

        <form>
          <div className="wink-form-field-container action-buttons">
            <button
              type="button"
              className="wink-button wink-button-secondary"
              onClick={() => setShowDisconnectConfirmationDialog(false)}
              disabled={deletingRemoteServicesConnection}
            >
              Cancel
            </button>
            <button
              type="button"
              className="wink-button wink-button-primary"
              onClick={async () => {
                // TODO: handle disconnection failure.
                await disconnect(deleteRemoteData);
                setShowDisconnectConfirmationDialog(false);
                setShowDisconnectedDialog(true);
              }}
              disabled={deletingRemoteServicesConnection}
            >
              {deletingRemoteServicesConnection ? (
                <SpinnerLabel label="Disconnect" />
              ) : (
                'Disconnect'
              )}
            </button>
          </div>
        </form>
      </Modal>

      <Modal isOpen={showDisconnectedDialog} modalClassName="modal-small">
        Your Squarespace Commerce and Mailchimp integration has been
        disconnected and you have been logged out.
        <form>
          <div className="wink-form-field-container action-buttons">
            <a href="/" className="wink-button wink-button-primary">
              Ok
            </a>
          </div>
        </form>
      </Modal>
    </div>
  );
};

Settings.propTypes = {
  selectedList: MAILCHIMP_LIST_PROP_TYPES,
  mailchimpUserRemoteListId: PropTypes.string,
  startSync: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  saveSelectedList: PropTypes.func.isRequired,
  disconnect: PropTypes.func.isRequired,
  deletingRemoteServicesConnection: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
  saveSelectedList,
  startSync,
  push,
  disconnect,
};

const mapStateToProps = createStructuredSelector({
  selectedList: selectSelectedList,
  mailchimpUserRemoteListId: selectMailchimpUserRemoteListId,
  deletingRemoteServicesConnection: simpleSelectorFor(
    'deletingRemoteServicesConnection'
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
