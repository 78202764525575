import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  selectSelectedList,
  simpleSelectorFor as simpleListsSelectorFor,
} from 'selectors/lists-selectors';
import { simpleSelectorFor as simpleSessionSelectorFor } from 'selectors/session-selectors';
import { saveSelectedList } from 'actions/lists-actions';
import { startSync } from 'actions/syncs-actions';
import MailchimpList from 'containers/mailchimp-list';
import { MAILCHIMP_LIST_PROP_TYPES } from 'constants/proptypes';
import SpinnerLabel from 'components/spinner-label';
import NonPhysicalProducts from '../non-physical-products';

const IntegrationSettings = ({
  selectedList = null,
  mailchimpUserRemoteListId = null,
  saveSelectedList,
  push,
  startSync,
  savingSelectedList,
  puttingSyncNonphysicalProductSetting,
}) => {
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    setShowSpinner(showSpinner || savingSelectedList);
  }, [savingSelectedList]);

  return (
    <>
      <h1>Integration Settings</h1>
      <p>
        A store will be created in the selected Mailchimp audience and your
        Squarespace Commerce products will be synced into that store.
        Squarespace Commerce orders will also be synced to Mailchimp on an
        ongoing basis.
      </p>

      <h2>Audience</h2>
      <p>
        Your Squarespace Commerce data and customers will be synced into the
        selected list.
      </p>

      <MailchimpList />

      <h2>Sync Configuration</h2>

      <NonPhysicalProducts />

      <form>
        <div className="wink-form-field-container action-buttons">
          <button
            type="button"
            className="wink-button wink-button-primary"
            onClick={async () => {
              await saveSelectedList({
                remoteListId: selectedList.id,
                name: selectedList.name,
              });

              await startSync(true);

              push('/sync');
            }}
            disabled={
              !selectedList?.id ||
              selectedList?.id === mailchimpUserRemoteListId ||
              showSpinner ||
              puttingSyncNonphysicalProductSetting
            }
          >
            {showSpinner ? (
              <SpinnerLabel label="Next: Initial Sync" />
            ) : (
              <>Next: Initial Sync</>
            )}
          </button>
        </div>
      </form>
    </>
  );
};

IntegrationSettings.propTypes = {
  saveSelectedList: PropTypes.func.isRequired,
  selectedList: MAILCHIMP_LIST_PROP_TYPES,
  push: PropTypes.func.isRequired,
  startSync: PropTypes.func.isRequired,
  savingSelectedList: PropTypes.bool.isRequired,
  mailchimpUserRemoteListId: PropTypes.string,
  puttingSyncNonphysicalProductSetting: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
  saveSelectedList,
  push,
  startSync,
};

const mapStateToProps = createStructuredSelector({
  selectedList: selectSelectedList,
  savingSelectedList: simpleListsSelectorFor('savingSelectedList'),
  puttingSyncNonphysicalProductSetting: simpleSessionSelectorFor(
    'puttingSyncNonphysicalProductSetting'
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IntegrationSettings);
