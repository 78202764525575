import { createSelector } from 'reselect';

export const selectSlice = (state) => state.session;

export const simpleSelectorFor = (field) =>
  createSelector(selectSlice, (state) => state[field]);

export const selectSyncNonPhysicalProducts = createSelector(
  selectSlice,
  (state) => state.squarespaceWebsite.syncNonPhysicalProducts
);

export const selectMailchimpUserRemoteListId = createSelector(
  selectSlice,
  (state) => state.mailchimpUser.remoteListId
);
