import React from 'react';

const ActivityIndicator = () => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 400 4"
    width="400px"
    height="4px"
  >
    <rect x="0" y="0" width="100%" height="100%" fill="#cccccc" />
    <g>
      <rect x="-50%" width="50%" height="4" />
      <animateTransform
        attributeName="transform"
        type="translate"
        from="0 0"
        to="600 0"
        dur="3000ms"
        repeatCount="indefinite"
      />
    </g>
  </svg>
);

ActivityIndicator.propTypes = {};

export default ActivityIndicator;
