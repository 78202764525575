import React from 'react';
import {
  MAILCHIMP_USER_PROP_TYPES,
  SQUARESPACE_WEBSITE_PROP_TYPES,
} from '../constants/proptypes';

const UserIdsDisplay = ({ squarespaceWebsite, mailchimpUser = null }) => (
  <div className="user-ids-display">
    Reference Number: SS{mailchimpUser?.id || '0000'}-{squarespaceWebsite.id}
  </div>
);

UserIdsDisplay.propTypes = {
  squarespaceWebsite: SQUARESPACE_WEBSITE_PROP_TYPES.isRequired,
  mailchimpUser: MAILCHIMP_USER_PROP_TYPES,
};

export default UserIdsDisplay;
