import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const Modal = ({ children, isOpen, modalClassName = '' }) => {
  useEffect(() => {
    document.activeElement.blur();

    if (isOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = '';
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="modal-container">
      <div className={`modal ${modalClassName}`}>{children}</div>
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  modalClassName: PropTypes.string,
};

export default Modal;
