import { hot } from 'react-hot-loader';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import ApplicationContainer from 'containers/application-container';
import {
  setSessionPath,
  setDashboardEnablementPath,
  setSquarespaceWebsiteSyncNonphysicalProductSettingPath,
  setRemoteServicesConnectionPath,
  setAuthenticityToken,
} from 'actions/session-actions';
import {
  setMailchimpListsPath,
  setMailchimpUserListPath,
} from 'actions/lists-actions';
import store, { history } from 'configure-store';
import {
  setSyncsPath,
  setSyncPath,
  setSquarespaceWebhookSubscriptionsPath,
} from 'actions/syncs-actions';
import {
  setMailchimpSignupOptionsPath,
  setMailchimpUsernameAvailabilityPath,
  setMailchimpUsernameSuggestionPath,
  setMailchimpSignupsPath,
} from 'actions/account-creation-actions';
import ScrollToTop from 'components/scroll-to-top';
import ErrorBoundary from 'components/error-boundary';

const HotReloadingContainer = hot(module)(Fragment);

const AppContainer = ({
  sessionPath,
  dashboardEnablementPath,
  squarespaceWebsiteSyncNonphysicalProductSettingPath,
  remoteServicesConnectionPath,
  mailchimpListsPath,
  syncsPath,
  syncPath,
  squarespaceWebhookSubscriptionsPath,
  authenticityToken,
  mailchimpUserListPath,
  mailchimpUsernameAvailabilityPath,
  mailchimpUsernameSuggestionPath,
  mailchimpSignupOptionsPath,
  mailchimpSignupsPath,
  bugsnagApiKey = null,
  bugsnagReleaseStage,
  revision = null,
}) => {
  store.dispatch(setAuthenticityToken(authenticityToken));
  store.dispatch(setSessionPath(sessionPath));
  store.dispatch(setDashboardEnablementPath(dashboardEnablementPath));
  store.dispatch(
    setSquarespaceWebsiteSyncNonphysicalProductSettingPath(
      squarespaceWebsiteSyncNonphysicalProductSettingPath
    )
  );
  store.dispatch(setRemoteServicesConnectionPath(remoteServicesConnectionPath));
  store.dispatch(setMailchimpListsPath(mailchimpListsPath));
  store.dispatch(setSyncsPath(syncsPath));
  store.dispatch(setSyncPath(syncPath));
  store.dispatch(
    setSquarespaceWebhookSubscriptionsPath(squarespaceWebhookSubscriptionsPath)
  );
  store.dispatch(setMailchimpUserListPath(mailchimpUserListPath));
  store.dispatch(
    setMailchimpUsernameAvailabilityPath(mailchimpUsernameAvailabilityPath)
  );
  store.dispatch(
    setMailchimpUsernameSuggestionPath(mailchimpUsernameSuggestionPath)
  );
  store.dispatch(setMailchimpSignupOptionsPath(mailchimpSignupOptionsPath));
  store.dispatch(setMailchimpSignupsPath(mailchimpSignupsPath));

  return (
    <Provider store={store}>
      <HotReloadingContainer>
        <ErrorBoundary
          bugsnagApiKey={bugsnagApiKey}
          bugsnagReleaseStage={bugsnagReleaseStage}
          revision={revision}
        >
          <ConnectedRouter history={history}>
            <ScrollToTop />
            <ApplicationContainer />
          </ConnectedRouter>
        </ErrorBoundary>
      </HotReloadingContainer>
    </Provider>
  );
};

AppContainer.propTypes = {
  sessionPath: PropTypes.string.isRequired,
  dashboardEnablementPath: PropTypes.string.isRequired,
  squarespaceWebsiteSyncNonphysicalProductSettingPath:
    PropTypes.string.isRequired,
  remoteServicesConnectionPath: PropTypes.string.isRequired,
  mailchimpListsPath: PropTypes.string.isRequired,
  syncsPath: PropTypes.string.isRequired,
  syncPath: PropTypes.string.isRequired,
  squarespaceWebhookSubscriptionsPath: PropTypes.string.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  mailchimpUserListPath: PropTypes.string.isRequired,
  mailchimpUsernameAvailabilityPath: PropTypes.string.isRequired,
  mailchimpUsernameSuggestionPath: PropTypes.string.isRequired,
  mailchimpSignupOptionsPath: PropTypes.string.isRequired,
  mailchimpSignupsPath: PropTypes.string.isRequired,
  bugsnagApiKey: PropTypes.string,
  bugsnagReleaseStage: PropTypes.string.isRequired,
  revision: PropTypes.string,
};

export default AppContainer;
