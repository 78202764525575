import React from 'react';
import PropTypes from 'prop-types';
import MailchimpLogo from './mailchimp-logo';
import PlusIcon from './icons/plus-icon';
import SquarespaceLogo from './squarespace-logo';

const Header = ({ children = '' }) => (
  <header>
    <div className="icons">
      <MailchimpLogo />
      <PlusIcon className="plus" />
      <SquarespaceLogo />
    </div>
    <div>{children}</div>
  </header>
);

Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
