import React from 'react';
import PropTypes from 'prop-types';

const ConversationIcon = ({
  alt = 'Conversation',
  className = '',
  size = 24,
  onClick = () => {},
}) => (
  <svg
    className={`wink-icon ${className}`}
    width={size}
    height={size}
    onClick={onClick}
    viewBox="0 0 24 24"
    aria-labelledby="ConversationIcon"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="ConversationIcon">{alt}</title>
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 11a3 3 0 01-3 3h-1v4h-2.764a2 2 0 01-1.789-1.106L14 14H9a3 3 0 01-3-3V3a3 3 0 013-3h12a3 3 0 013 3v8zm-3 1h-3v4h-.764l-2-4H9a1 1 0 01-1-1V3a1 1 0 011-1h12a1 1 0 011 1v8a1 1 0 01-1 1z"
      />
      <path d="M15 20c.076 0 .15-.003.225-.008a4.994 4.994 0 01-1.461-1.756L13.646 18H8.764l-2 4H6v-4H3a1 1 0 01-1-1V9a1 1 0 011-1V6a3 3 0 00-3 3v8a3 3 0 003 3h1v4h2.764a2 2 0 001.789-1.106L10 20h5z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

ConversationIcon.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
  onClick: PropTypes.func,
};

export default ConversationIcon;
