import React from 'react';
import PropTypes from 'prop-types';

const MenuLeftIcon = ({
  alt = 'Menu Left',
  className = '',
  size = 24,
  onClick = () => {},
}) => (
  <svg
    className={`wink-icon ${className}`}
    width={size}
    height={size}
    onClick={onClick}
    viewBox="0 0 24 24"
    aria-labelledby="MenuLeftIcon"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="MenuLeftIcon">{alt}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.293 6.293l1.414 1.414L10.414 12l4.293 4.293-1.414 1.414L7.586 12l5.707-5.707z"
    />
  </svg>
);

MenuLeftIcon.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
  onClick: PropTypes.func,
};

export default MenuLeftIcon;
