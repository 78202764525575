import { createHashHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import {
  configureStore,
  combineReducers,
  applyMiddleware,
} from '@reduxjs/toolkit';
import sessionReducer from './reducers/session-reducer';
import listsReducer from './reducers/lists-reducer';
import syncsReducer from './reducers/syncs-reducer';
import accountCreationReducer from './reducers/account-creation-reducer';

export const history = createHashHistory();

export default configureStore({
  reducer: combineReducers({
    router: connectRouter(history),
    session: sessionReducer,
    lists: listsReducer,
    syncs: syncsReducer,
    accountCreation: accountCreationReducer,
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(routerMiddleware(history)),
  devTools: true,
});
