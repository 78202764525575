import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  startPollingSync,
  stopPollingSync,
  getSync,
} from 'actions/syncs-actions';
import { enableDashboard } from 'actions/session-actions';
import { simpleSelectorFor } from 'selectors/syncs-selectors';
import { SYNC_PROP_TYPES } from 'constants/proptypes';
import SyncStatus from 'components/sync-status';
import Feedback from 'components/feedback';
import TimeCircleIcon from '../../components/icons/time-circle-icon';

const InitialSync = ({
  enableDashboard,
  sync = null,
  startPollingSync,
  stopPollingSync,
  getSync,
}) => {
  useEffect(() => {
    getSync(sync?.id || 'latest');

    return () => {
      stopPollingSync();
    };
  }, []);

  useEffect(() => {
    stopPollingSync();
    if (sync?.id) startPollingSync('latest');
  }, [sync?.id]);

  return (
    <>
      <h1>Syncing Squarespace Commerce Data</h1>

      <p>
        We are performing an initial sync of your products, customer profiles,
        and store products. Syncing can take a while. You can leave this screen
        and syncing will continue in the background.
      </p>
      <div className="overall-status">
        {!sync && (
          <Feedback label="Please hold..." icon={TimeCircleIcon}>
            Your sync has been enqueued and should start shortly.
          </Feedback>
        )}
        {sync?.status === 'succeeded' && (
          <Feedback label="Success!" status="success">
            Your initial sync was completed successfully.
          </Feedback>
        )}
        {sync?.status === 'retrying' && (
          <Feedback label="Retrying" status="warning">
            We encountered a problem completing your initial sync. The
            problematic item will be retried automatically.
          </Feedback>
        )}
        {sync?.status === 'failed' && (
          <Feedback label="Sync Failed" status="error">
            There was an unexpected problem completing your initial sync.
          </Feedback>
        )}
      </div>
      <SyncStatus sync={sync} />
      <form>
        <div className="wink-form-field-container action-buttons">
          <button
            type="button"
            className="wink-button wink-button-primary"
            onClick={() => {
              enableDashboard();
            }}
          >
            Go to Dashboard
          </button>
        </div>
      </form>
    </>
  );
};

InitialSync.propTypes = {
  enableDashboard: PropTypes.func.isRequired,
  sync: SYNC_PROP_TYPES,
  startPollingSync: PropTypes.func.isRequired,
  stopPollingSync: PropTypes.func.isRequired,
  getSync: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  enableDashboard,
  startPollingSync,
  stopPollingSync,
  getSync,
};

const mapStateToProps = createStructuredSelector({
  sync: simpleSelectorFor('sync'),
});

export default connect(mapStateToProps, mapDispatchToProps)(InitialSync);
