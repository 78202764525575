import PropTypes from 'prop-types';

export const SQUARESPACE_WEBSITE_PROP_TYPES = PropTypes.exact({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  syncNonPhysicalProducts: PropTypes.bool.isRequired,
  setupStep: PropTypes.string.isRequired,
  showDashboard: PropTypes.bool.isRequired,
  setupComplete: PropTypes.bool.isRequired,
  syncInterval: PropTypes.string.isRequired,
  nextSyncIn: PropTypes.string.isRequired,
  mailchimpList: PropTypes.exact({
    remoteListId: PropTypes.string.isRequired,
    listName: PropTypes.string.isRequired,
  }),
  loginCount: PropTypes.number.isRequired,
  lastLoginAt: PropTypes.string,
  justLoggedIn: PropTypes.bool.isRequired,
  justSignedUp: PropTypes.bool.isRequired,
});

export const MAILCHIMP_USER_PROP_TYPES = PropTypes.exact({
  id: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  nickname: PropTypes.string.isRequired,
  authorized: PropTypes.bool.isRequired,
  remoteListId: PropTypes.string,
  listName: PropTypes.string,
});

export const MAILCHIMP_LIST_PROP_TYPES = PropTypes.exact({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

export const ERROR_PROP_TYPES = PropTypes.exact({
  message: PropTypes.string.isRequired,
});

export const VALIDATION_ERROR_PROP_TYPES = PropTypes.exact({
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  detail: PropTypes.string.isRequired,
  errors: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    details: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    messages: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    fullMessages: PropTypes.object.isRequired,
  }).isRequired,
});

export const SYNC_PROP_TYPES = PropTypes.exact({
  id: PropTypes.number.isRequired,
  batchId: PropTypes.string.isRequired,
  triggeredBy: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['in_progress', 'succeeded', 'failed', 'retrying'])
    .isRequired,
  full: PropTypes.bool.isRequired,
  detail: PropTypes.string,
  lastSyncErrorFriendlyErrorMessage: PropTypes.string,
  products: PropTypes.number.isRequired,
  profiles: PropTypes.number.isRequired,
  orders: PropTypes.number.isRequired,
  createdAgo: PropTypes.string.isRequired,
  updatedAgo: PropTypes.string.isRequired,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
});

export const SYNCS_PROP_TYPE = PropTypes.arrayOf(SYNC_PROP_TYPES);

export const SYNCS_PAGINATION_TYPE = PropTypes.exact({
  count: PropTypes.number.isRequired,
  items: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  last: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  from: PropTypes.number.isRequired,
  to: PropTypes.number.isRequired,
  prev: PropTypes.number,
  next: PropTypes.number,
});

export const MAILCHIMP_USERNAME_AVAILABILITY_TYPE = PropTypes.exact({
  accountAvailable: PropTypes.bool.isRequired,
});

export const MAILCHIMP_USERNAME_SUGGESTION_TYPE = PropTypes.exact({
  usernames: PropTypes.arrayOf(PropTypes.string),
});

const STATE_PROP_TYPE = PropTypes.exact({
  name: PropTypes.string.isRequired,
  abbreviation: PropTypes.string.isRequired,
});

const COUNTRY_PROP_TYPE = PropTypes.exact({
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  stateProvinceRequired: PropTypes.bool.isRequired,
  postalCodeRequired: PropTypes.bool.isRequired,
});

export const SIGNUP_OPTIONS_TYPE = PropTypes.exact({
  countries: PropTypes.arrayOf(COUNTRY_PROP_TYPE).isRequired,
  states: PropTypes.exact({
    us: PropTypes.arrayOf(STATE_PROP_TYPE),
    ca: PropTypes.arrayOf(STATE_PROP_TYPE),
  }).isRequired,
});
